import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../shared/frame/Typography';
import { Box, Button } from '@mui/material';
import './dataspace.scss';
import DsOverview from './components/dsoverview';
import DsFeature from './components/dsfeature';
import DsApplicationField from './components/dsapplicationfield';
import { MainHeader } from '../../shared/frame/MainHeader/MainHeader';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';

const Dataspace = () => {
    const { t } = useTranslation('dataspace');
    const [activeButton, setActiveButton] = useState<string>('');
    const [isFixed, setIsFixed] = useState<boolean>(false);
    const sectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const handleScroll = () => {
        setIsFixed(window.scrollY > 275);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const tabButtons = [
        { key: 'overview', label: t('content.overview.overview'), panelKey: 'overview' },
        { key: 'feature', label: t('content.feature.feature'), panelKey: 'feature' },
        { key: 'applicationfield', label: t('content.applicationfield.applicationfield'), panelKey: 'applicationfield' },
    ];

    const [, setActivePanel] = useState<string>('overview');

    const scrollToSection = (elementRef: React.MutableRefObject<HTMLButtonElement | null>, buttonKey: string, panelKey: string) => {
        setActiveButton(buttonKey);
        setActivePanel(panelKey);

        if (elementRef.current !== null) {
            elementRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })

            setActiveButton(buttonKey);
        }
    };
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const index = sectionRefs.findIndex((ref) => ref.current === entry.target);
                if (index !== -1) {
                    setActiveButton(tabButtons[index].key);
                }
            }
        });
    };
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.2,
        };
        const observer = new IntersectionObserver(handleIntersection, options);
        sectionRefs.forEach((ref) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            observer.disconnect();
        };
    });

    return (
        <main className='dataspace'>
            {sectionRefs.map((sectionRef, index) => (
                <Box
                    key={index}
                    ref={sectionRef}
                    style={{ paddingTop: isFixed ? '0px' : '0px' }}
                >
                    {index === 0 && <DsOverview />}
                    {index === 1 && <DsFeature />}
                    {index === 2 && <DsApplicationField />}
                </Box>
            ))}
        </main>
    );
};

export default Dataspace;
