import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';
import contextimg from '../../../../../../assets/image/context_technical.png'
import contextimg2 from '../../../../../../assets/image/context-technical2.png'


interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function Sdfactorycontext({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.sdfactory && (
                        <>
                            <Typography variant='h4'>{item.content.sdfactory?.context?.title}</Typography>
                            <Typography>{item.content.sdfactory?.context?.desc}</Typography>
                            <Typography variant='h6'>{item.content.sdfactory?.context?.businesscontext}</Typography>
                            <img className='step-img' src={item.content.sdfactory?.context?.businesslink} alt="" />
                            <Typography variant='h6'>{item.content.sdfactory?.context?.technicalcontext}</Typography>
                            <Typography>{item.content.sdfactory?.context?.technicaldesc}</Typography>
                            <img src={contextimg} alt="" className='step-img' />
                            <Typography>{item.content.sdfactory?.context?.technicaldesc2}</Typography>
                            <img src={contextimg2} alt="" className='step-img' />
                            <Typography>{item.content.sdfactory?.context?.technicaldesc3}</Typography>
                            <ul>
                                <li><Typography>{item.content.sdfactory?.context?.list.legal}</Typography></li>
                                <li><Typography>{item.content.sdfactory?.context?.list.serviceoffering}</Typography></li>
                                <li><Typography>{item.content.sdfactory?.context?.list.physicalresource}</Typography></li>
                                <li><Typography>{item.content.sdfactory?.context?.list.virtualresouce}</Typography></li>
                                <li><Typography>{item.content.sdfactory?.context?.list.instantiated}</Typography></li>
                            </ul>
                            <div className="context-footer">
                                <div className="link">
                                    <a href={item.content.sdfactory?.context?.trustframework}><Typography>{item.content.sdfactory?.context?.trustframework}</Typography></a>
                                </div>
                                <div className="link">
                                    <a href={item.content.sdfactory?.context?.trustframeworklinkV22}><Typography>{item.content.sdfactory?.context?.trustframeworkV22}</Typography></a>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </section>
    )
}

export default Sdfactorycontext