import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark"
import DownloadIcon from "@mui/icons-material/Download"
import { MainHeader } from "../../shared/frame/MainHeader/MainHeader"
import { useState } from "react"
import { Button } from "@quantumsurf/portal-shared-components"

export default function Terms() {
  const { t } = useTranslation()

  const [isMobile] = useState<boolean>(() => {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  });

  const handleClick = () => {
    window.open(`${process.env.REACT_APP_RESOURCES_URL}/terms.pdf#navpanes=0&toolbar=0&statusbar=0&view=FitH`, "_blank", "noopener, noreferrer");

  };

  return (
    <main>
      <MainHeader
        headerHeight={100}
        subTitleWidth={1000}
        title={t("pages.terms")}
        // subTitle={t("pages.terms")}
        titleIcon={<BrandingWatermarkIcon className="header-icon" />}
      />
      <section className="main-section">
        <div style={{ height: '100%' }}>
          {isMobile ?
            <Box><Button className="button" onClick={() => handleClick()}>
              <DownloadIcon
                sx={{
                  color: "#fff",
                  fontSize: "2rem",
                  marginRight: "5px",
                  marginTop: "0px",
                }}
              />
              {t("pages.terms")}
            </Button></Box> :
            <embed src={`${process.env.REACT_APP_RESOURCES_URL}/terms.pdf#navpanes=0&toolbar=0&statusbar=0&view=FitH`} type="application/pdf" width={'100%'} height={'100%'} />

          }
        </div>
      </section>
    </main>
  )
}
