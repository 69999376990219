import { Typography } from '../../../shared/frame/Typography';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import usecaseitem from '../../../../item/usecaseitem.json'
import './../dataspace.scss'

export default function DsApplicationField() {
    const { t, i18n } = useTranslation('dataspace', { keyPrefix: 'applicationfield' });
    const selectusecase = usecaseitem.item?.[i18n.language as keyof typeof usecaseitem.item];
    const contentItems = selectusecase.filter((item) => item.id);
    return (
        <section className='feature'>
            <Typography variant='h3' sx={{ margin: '10px 0px' }}>{t('title')}</Typography>
            <Typography variant='h6' sx={{ margin: '10px 0px' }}>{t('subtitle')}</Typography>
            <Box className='featurebox'>
                {contentItems.map((item) => (
                    <Box className='card'>
                        <div key={item.id}>
                            <Typography variant='h6' sx={{ color: '#007BFF', fontWeight: 'bold' }}>{item.cardtitle}</Typography>
                            <Typography variant='body1' sx={{ marginTop: '15px' }}>{item.description}</Typography>
                        </div>
                    </Box>
                ))}
            </Box>
        </section>
    )
}
