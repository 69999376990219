import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import "./Footer.scss"
import { Navigation } from "../../basic/Navigation"
import { IconButton } from "../../basic/IconButton"
import { useEffect, useState } from "react"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Logo } from "@quantumsurf/portal-shared-components"


export const Footer = ({ pages }: { pages: string[] }) => {
  const { t } = useTranslation()

  const items = pages.map((page) => ({
    to: page,
    title: t(`pages.${page}`),
  }))

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const [showScrollToTop, setShowScrollToTop] = useState(false)

  const toggleVisibility = () => {
    setShowScrollToTop(window.pageYOffset > 350)
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)
  }, [])

  return (
    <footer>
      {showScrollToTop && (
        <IconButton
          color="secondary"
          onClick={scrollToTop}
          sx={{ position: 'fixed', right: '10px', bottom: '30px', backgroundColor: "#0a2671", zIndex: 9 }}        >
          <ArrowUpwardIcon sx={{ color: 'white' }} />
        </IconButton>
      )}
      <div className="footer">
        <div className="footer-content">
          <div className="footer-link">
            <div className="footer-img">
              <Logo variant="smallwhitenl" />
            </div>
            <div className="footer-text">
              <Navigation
                items={items}
                component={NavLink}
                sx={{
                  padding: "10px 10px",
                  color: "#707070",
                }}
              />
              <span className="company-info">
                {`${t("content.footer.crn")}`} &nbsp;&nbsp; | &nbsp;&nbsp;
                {`${t("content.footer.call")}`} <br/>
                {`${t("content.footer.address")}`}
              </span>
              <br />
              <span className="company-copyright">
                {`${t("content.footer.copyright")}`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
