import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';
import { Divider } from '@mui/material';
import formatcode from '../../../../../../assets/image/didcode.png'

interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function MIWDIDandPsp({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.wallet && (
                        <>
                            <div className="did">
                                <Typography variant='h4'>{item.content?.wallet?.did?.title}</Typography>
                                <Typography>{item.content?.wallet?.did?.description}</Typography>
                                <img className='step-img' src={formatcode} alt="formatcode" />
                            </div>
                            <Divider sx={{ margin: '10px', border: 'none' }} />
                            <div className="dsp">
                                <Typography variant='h4'>{item.content?.wallet?.dsp?.title}</Typography>
                                <Typography>{item.content?.wallet?.dsp?.description}</Typography>
                                {Object.entries(item.content?.wallet?.dsp?.list || {}).map(([key, value]) => (
                                    <div>
                                        <Typography variant="body1"><li>{value}</li></Typography>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            ))}
        </section>
    )
}


export default MIWDIDandPsp;