import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../../../shared/frame/Button';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import DatachainFeature from './components/datachainfeature';
import Datachainbusiness from './components/datachainbusiness';
import Datachainfunction from './components/datachainfunction';

interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function Datachain({ serviceitem }: ServiceItemProps) {
    const { id } = useParams<{ id: string }>();
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof ServiceItem['item']] || [];
    const selectedItem = selectedLanguageItems.find((item: Item) => item.id === id);
    const [activeButton, setActiveButton] = useState<string>('');
    const [isFixed, setIsFixed] = useState<boolean>(false);
    const handleScroll = () => {
        setIsFixed(window.scrollY > 275);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // IntersectionObserver 관련 state 및 effect
    const [, setActivePanel] = useState<string>('overview');

    const sectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const index = sectionRefs.findIndex((ref) => ref.current === entry.target);
                if (index !== -1) {
                    setActiveButton(tabButtons[index].key);
                }
            }
        });
    };

    // 해당 섹션으로 스크롤
    const scrollToSection = (elementRef: React.MutableRefObject<HTMLButtonElement | null>, buttonKey: string, panelKey: string) => {
        setActiveButton(buttonKey);
        setActivePanel(panelKey);

        if (elementRef.current !== null) {
            elementRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })

            setActiveButton(buttonKey);
        }
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.2,
        };
        const observer = new IntersectionObserver(handleIntersection, options);
        sectionRefs.forEach((ref) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            observer.disconnect();
        };
    });

    // Tab 버튼 목록
    const tabButtons = [
        { key: 'feature', label: t('content.service.tab.feature'), panelKey: 'feature' },
        { key: 'consumer', label: t('content.service.tab.consumer'), panelKey: 'consumer' },
        { key: 'irs', label: t('content.service.tab.irs'), panelKey: 'irs' },
    ];

    return (
        <main>
            <div
                className={`dataspace-tab ${isFixed ? 'fixed' : '0px'}`}
                style={{
                    gridTemplateColumns: `repeat(${sectionRefs.length},1fr)`
                }}
            >
                {tabButtons.map((tabButton, index) => (
                    <Button
                        key={tabButton.key}
                        onClick={() => scrollToSection(sectionRefs[index], tabButton.key, tabButton.panelKey)}
                        sx={{
                            color: activeButton === tabButton.key ? 'black' : '#888888',
                            '&:hover': {
                                backgroundColor: activeButton === tabButton.key ? 'transparent' : 'grey',
                            },
                        }}
                    >
                        {tabButton.label}
                    </Button>
                ))}
            </div>
            {sectionRefs.map((sectionRef, index) => (
                <Box
                    key={index}
                    ref={sectionRef}
                >
                    {index === 0 && selectedItem && <DatachainFeature serviceitem={serviceitem} selectedItemId={selectedItem.id || ''} />}
                    {index === 1 && selectedItem && <Datachainbusiness serviceitem={serviceitem} selectedItemId={selectedItem.id || ''} />}
                    {index === 2 && selectedItem && <Datachainfunction serviceitem={serviceitem} selectedItemId={selectedItem.id || ''} />}
                </Box>
            ))}
        </main>
    );
}

export default Datachain