import { Box, Typography, debounce } from "@mui/material";
import { useTranslation } from "react-i18next";
import principles_ko from '../../../../../assets/image/chart_principles_ko.png'
import principles_en from '../../../../../assets/image/chart_principles_en.png'
import principles_mobile_ko from '../../../../../assets/image/chart_principles_mobile_ko.png'
import principles_mobile_en from '../../../../../assets/image/chart_principles_mobile_en.png'
import { useEffect, useState } from "react";

function PrinciplesSection() {

    const { t, i18n } = useTranslation()

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const handleResize = debounce(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }, 10);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => { // cleanup 
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    return (
        <main>
            <section className="notice-section" style={{ width: '100%' }}>
                <Box sx={{ padding: '20px 0' }}>
                    <Typography variant="h3" style={{ fontWeight: 'bold', fontSize: '40px' }}>{t('content.home.principles.title')}</Typography>
                </Box>
                <img src={windowSize.width > 800 ? 
                    (i18n.language === 'ko' ? principles_ko : principles_en) : 
                    (i18n.language === 'ko' ? principles_mobile_ko : principles_mobile_en)} style={{ position: 'relative', width: '100%', marginTop: 20, marginLeft: windowSize.width > 800 ? '0' : '-10px'}} />
            </section>
        </main>
    )
}

export default PrinciplesSection;