import React from "react"
import { Typography } from "../../../shared/frame/Typography"
import { useTranslation } from "react-i18next"
import "../Partner.scss"
import ids_ram from '../../../../assets/image/ids-ram-png.png'

const Overview = () => {
    const { t } = useTranslation()
    return (
        <>
            <section className="partnersection">
                <Typography variant="h4">{t("content.partner.details.step1.title")}</Typography>
                <Typography variant="h5">{t("content.partner.details.step1.subtitle")}</Typography>
                <div style={{ margin: "30px 0px", display: "flex", justifyContent: "center" }}>
                    <img src={ids_ram} alt="" style={{ width: "100%" }} />
                </div>
            </section >
        </>
    )
}

export default Overview