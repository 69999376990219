import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "../../../../styles/swiper.scss"
import { useTranslation } from "react-i18next";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { SwiperHeaderTitle } from "../../../../shared/basic/SwiperHeader/Component/SwiperHeadertitle";
import { Box, Typography, debounce } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import SwiperCore from "swiper";
import usecaseitem from "../../../../../item/usecaseitem.json"
import './application.scss'

const Application = () => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [isArrow, setIsArrow] = useState({
    left:true,
    right:false
  });

  const navigate = useNavigate()
  const { t, i18n } = useTranslation();
  const useitem = usecaseitem.item?.[i18n.language as keyof typeof usecaseitem.item];
  const link = () => {
    navigate('/usecase')
  }

  const handleResize = debounce(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }, 10);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => { // cleanup 
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  return (
    <section className="application-section">
      <div className="swiper-header">
        <div className="swiper-option">
          <SwiperHeaderTitle title={t("content.home.applicationfield.title")} titleTextVariant="h4" titleStyle={{ fontWeight: 'bold', fontSize: '40px;' }} />
          <div className="button-wrapper">
            <button
              className="button"
              onClick={() => {
                if (swiper) {
                  swiper.slidePrev();
                }
              }}
            >
              <ArrowBackIosNewIcon sx={{ color: "#fff", fontSize: "20px" }} />
            </button>
            <button
              className="button"
              onClick={() => {
                if (swiper) {
                  swiper.slideNext();
                }
              }}
            >
              <ArrowForwardIosIcon sx={{ color: "#fff", fontSize: "20px" }} />
            </button>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div className="button-wrapper">
            <div style={{zIndex:400}}>
              {isArrow.left == false && <KeyboardArrowLeftIcon sx={{ color: "#333", width: "20px", fontSize: "20px" }} onClick={() => {
                if (swiper) {
                  swiper.slidePrev();
                }
              }} />}
            </div>
            <div style={{zIndex:400}}>
              {isArrow.right == false && <KeyboardArrowRightIcon sx={{ color: "#333", width: "20px", fontSize: "20px" }}
                onClick={() => {
                  if (swiper) {
                    swiper.slideNext();
                  }
                }} />}
            </div>
        </div>
        <Swiper
          pagination={true}
          spaceBetween={10}
          slidesPerView={windowSize.width > 800 ? Math.floor(windowSize.width / 280) : 1}
          setWrapperSize={true}
          onSwiper={(swiper) => {
            setSwiper(swiper);
          }}
          onSlideChange={(swiper) => {
            setIsArrow({left:swiper?.isBeginning, right:swiper?.isEnd})
          }}
        >
          {useitem?.map((item, id) => (
            <SwiperSlide key={id}>
              <Box className='usecase-box'>
                <div className='container'>
                  <div className="image">
                    <img src={item.image} alt="" />
                  </div>
                  <div className="text">
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{item.title}</Typography>
                    <div className='usecase-content'>
                      <Typography variant="body1">
                        {item.description}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Application;
