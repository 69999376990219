import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Divider } from '@mui/material';
import partication from '../../../../assets/image/partication.png';

import Corelist from './list/corelist'
import Intermediary from './list/intermediarylist';
import Software from './list/software';
import Governance from './list/governance';


interface CoreItemProps {
    partneritem: PartnerItem
}
function Type({ partneritem }: CoreItemProps) {
    const { t, i18n } = useTranslation('', { keyPrefix: 'content.partner.details.step2' });
    const partneritems = partneritem.item?.[i18n.language as keyof typeof partneritem.item];
    const coreitems = partneritems?.filter((item) => item.coreparticipant);

    return (
        <>
            <main style={{ backgroundColor: '#f9f9f9' }}>
                <section className="partnersection">
                    <Typography variant="h4">{t('title')}</Typography>
                    <Typography variant="h5">{t('description')}</Typography>
                    <Typography variant="h6">{t('subtitle')}</Typography>
                    <img src={partication} alt={partication} style={{ width: '100%', borderRadius: 10, margin: '10px 0px' }} />
                    <div className="partner-roles">
                        {coreitems.map((item, index) => (
                            <div key={index}>
                                <Typography sx={{ fontSize: '24px', marginBottom: '20px' }}>{t('category.coreparticipant.title')}</Typography>
                                <div className="core">
                                    <Corelist partneritem={partneritem} />
                                </div>
                                <Divider sx={{ margin: '20px 0px' }} />

                                <Typography sx={{ fontSize: '24px', marginBottom: '20px' }}>{t('category.intermediary.title')}</Typography>
                                <div className="inter">
                                    <Intermediary partneritem={partneritem} />
                                </div>
                                <Divider sx={{ margin: '20px 0px' }} />

                                <Typography sx={{ fontSize: '24px', marginBottom: '20px' }}>{t('category.software.title')}</Typography>
                                <div className="soft">
                                    <Software partneritem={partneritem} />
                                </div>
                                <Divider sx={{ margin: '20px 0px' }} />

                                <Typography sx={{ fontSize: '24px', marginBottom: '20px' }}>{t('category.governance.title')}</Typography>
                                <div className="soft">
                                    <Governance partneritem={partneritem} />
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </main>
        </>
    );
};

export default Type;
