import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import Main from "./Main"
import NotFound from "./shared/basic/NotFound"
import Terms from "./pages/Terms"
import Home from "./pages/home"
import Imprint from "./pages/Imprint"
import Service from "./pages/Service/Service"
import Privacy from "./pages/Privacy"
import CookiePolicy from "./pages/CookiePolicy"
import Usecase from "./pages/UseCase"
import Partner from "./pages/Partner"
import HelpCenter from "./pages/HelpCenter"
import Faq from "./pages/HelpCenter/components/Faq"
import Inquiry from "./pages/Inquiry"
import ServiceInquriy from "./pages/Inquiry/component/serviceinquiry"
import TrafficInquriy from "./pages/Inquiry/component/trafficinquiry"
import MoneyInquriy from "./pages/Inquiry/component/moneyinquiry"
import BusinessInquriy from "./pages/Inquiry/component/businessinquiry"
import SolutionInquiry from "./pages/Inquiry/component/solutioninquiry"
import Serviceitem from './pages/ServiceItem/Serviceitem';
import serviceitem from './../item/serviceitem.json'
import partneritem from './../item/partneritem.json'
import Dataspace from './pages/Dataspace/dataspace';
import UsecaseDetail from './pages/Usecasedetail';
import JoinInquriy from './pages/Inquiry/component/joinquiry';
import Onboarding from './pages/Onboarding';
import { PAGES } from '../types/Constants';

const AuthorizingRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />

          <Route path='usecase' element={<Outlet />} >
            <Route index element={<Usecase />} />
            <Route path=":id" element={<Outlet />}>
              <Route
                index
                element={<UsecaseDetail />}
              />
            </Route>
          </Route>


          <Route path='dataspace' element={<Dataspace />} />

          <Route path="service" element={<Outlet />} >
            <Route index element={<Service />} />
            <Route path=":id" element={<Outlet />}>
              <Route
                index
                element={<Serviceitem serviceitem={serviceitem} />}
              />
            </Route>
          </Route>
          <Route path={PAGES.ONBOARDING} element={<Onboarding />} />

          <Route path="partner" element={<Partner partneritem={partneritem} />} />

          <Route path="support" element={<Outlet />}>
            <Route index element={<HelpCenter />} />
            <Route path="faq" element={<Outlet />} >
              <Route index element={<Faq />} />
              <Route path="inquiry" element={<Outlet />}>
                <Route index element={<Inquiry />}></Route>
                <Route path="service" element={<Outlet />}>
                  <Route index element={<ServiceInquriy />} />
                </Route>
                <Route path="solution" element={<Outlet />}>
                  <Route index element={<SolutionInquiry />} />
                </Route>
                <Route path="traffic" element={<Outlet />}>
                  <Route index element={<TrafficInquriy />} />
                </Route>
                <Route path="money" element={<Outlet />}>
                  <Route index element={<MoneyInquriy />} />
                </Route>
                <Route path="business" element={<Outlet />}>
                  <Route index element={<BusinessInquriy />} />
                </Route>
                <Route path="join" element={<Outlet />}>
                  <Route index element={<JoinInquriy />} />
                </Route>
                <Route index element={<BusinessInquriy />} />
              </Route>
            </Route>
          </Route>

          <Route path="imprint" element={<Imprint />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="cookiepolicy" element={<CookiePolicy />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default AuthorizingRouter
