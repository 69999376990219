import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';
import { Divider } from '@mui/material';


interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function Bpdmfeature({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.bpdm && (
                        <>
                            <div className="bpdm">
                                <Typography variant="h4" sx={{ fontWeight: 'bold', color: "#02a2671" }}>{item.content.bpdm.content?.title}</Typography>
                                <Typography variant="h6">{item.content.bpdm.content?.subtitle}</Typography>
                                <Typography variant="body1">{item.content.bpdm.content?.description}</Typography>
                                <Divider sx={{ margin: '10px', border: 'none' }} />
                                {Object.entries(item.content?.bpdm?.content?.bpn || {}).map(([key, value]) => (
                                    <>
                                        <Typography variant="body1"><li>{value}</li></Typography>
                                    </>
                                ))}
                            </div>

                            <Divider sx={{ margin: '10px', border: 'none' }} />
                            <div className="goal">
                                <Typography variant="h6">{item.content.bpdm.goaloverview?.title}</Typography>
                                <table>
                                    <thead>
                                        <th>{item.content.bpdm.goaloverview?.table.th.priorty}</th>
                                        <th>{item.content.bpdm.goaloverview?.table.th.goal}</th>
                                    </thead>
                                    <tbody>
                                        {['global', 'master', 'sharing', 'sme', 'partner'].map((row) => (
                                            <tr key={row}>
                                                <th>{(item.content?.bpdm?.goaloverview?.table?.td as Record<string, string | undefined>)?.[row]}</th>
                                                <td>{(item.content?.bpdm?.goaloverview?.table?.td as Record<string, string | undefined>)?.[`${row}td`]}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <Divider sx={{ margin: '10px', border: 'none' }} />
                            <div className="request">
                                <Typography variant="h6">{item.content.bpdm.requestoverview?.title}</Typography>
                                <img className='step-img' src={item.content.bpdm.requestoverview?.img} alt={item.content.bpdm.requestoverview?.title} />
                                <Typography variant="body1">{item.content.bpdm.requestoverview?.desc}</Typography>
                                <table>
                                    <thead>
                                        <th>{item.content.bpdm.requestoverview?.table.th?.priorty}</th>
                                        <th>{item.content.bpdm.requestoverview?.table.th.goal}</th>
                                        <th>{item.content.bpdm.requestoverview?.table.th.explantion}</th>
                                    </thead>
                                    <tbody>
                                        {['r1', 'r2', 'r3', 'r4', 'r5', 'r6'].map((row) => (
                                            <tr key={row}>
                                                <th>{(item.content?.bpdm?.requestoverview?.table?.td as Record<string, string | undefined>)?.[row]}</th>
                                                <td>{(item.content?.bpdm?.requestoverview?.table?.td as Record<string, string | undefined>)?.[`${row}goal`]}</td>
                                                <td>{(item.content?.bpdm?.requestoverview?.table?.td as Record<string, string | undefined>)?.[`${row}explantion`]}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <Divider sx={{ margin: '10px', border: 'none' }} />
                            <div className="quailty">
                                <Typography variant="h6">{item.content.bpdm.quality?.title}</Typography>
                                <img className='step-img' src={item.content.bpdm.quality?.img} alt={item.content.bpdm.quality?.title} />
                                <table>
                                    <thead>
                                        <th>{item.content.bpdm.quality?.table.th?.priorty}</th>
                                        <th>{item.content.bpdm.quality?.table.th.goal}</th>
                                        <th>{item.content.bpdm.quality?.table.th.explantion}</th>
                                    </thead>
                                    <tbody>
                                        {['security', 'integrity', 'legally', 'correctness', 'reliability', 'functional', 'sensitivity'].map((row) => (
                                            <tr key={row}>
                                                <th>{(item.content?.bpdm?.quality?.table?.td as Record<string, string | undefined>)?.[row]}</th>
                                                <td>{(item.content?.bpdm?.quality?.table?.td as Record<string, string | undefined>)?.[`${row}goal`]}</td>
                                                <td>{(item.content?.bpdm?.quality?.table?.td as Record<string, string | undefined>)?.[`${row}scenario`]}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </section>
    )
}

export default Bpdmfeature