import { IAction, IPage } from "./maintype"
import { ACTIONS, PAGES } from "./Constants"
import Home from "../components/pages/home"
import Imprint from "../components/pages/Imprint"
import CookiePolicy from "../components/pages/CookiePolicy"
import Privacy from "../components/pages/Privacy"
import Terms from "../components/pages/Terms"
import SetLang from "../components/action/SetLang"
import HelpCenter from "../components/pages/HelpCenter"
import { Route } from "react-router-dom"
import Faq from "../components/pages/HelpCenter/components/Faq"
import Inquiry from "../components/pages/Inquiry"

import ServiceInquiry from "../components/pages/Inquiry/component/serviceinquiry"
import TrafficInquiry from "../components/pages/Inquiry/component/trafficinquiry"
import MoneyInquiry from "../components/pages/Inquiry/component/moneyinquiry"
import BusinessInquiry from "../components/pages/Inquiry/component/businessinquiry"
import SolutionInquiry from "../components/pages/Inquiry/component/solutioninquiry"


export const ALL_PAGES: IPage[] = [
  { name: PAGES.ROOT, element: <Home /> },
  { name: PAGES.HOME, element: <Home /> },

  { name: PAGES.IMPRINT, element: <Imprint /> },
  { name: PAGES.PRIVACY, element: <Privacy /> },
  { name: PAGES.TERMS, element: <Terms /> },
  { name: PAGES.COOKIE_POLICY, element: <CookiePolicy /> },

  {
    name: PAGES.SUPPORT,
    isRoute: true,
    role: "viewfaq",
    element: (
      <Route
        key={PAGES.SUPPORT}
        path={PAGES.SUPPORT}
        element={<HelpCenter />}>
        <Route index element={null}></Route>
        <Route key={PAGES.FAQ} path={PAGES.FAQ} element={<Faq />}>
          <Route index element={null}></Route>
          <Route key={PAGES.INQUIRY} path={PAGES.INQUIRY} element={<Inquiry />} >
            <Route index element={null}></Route>
            <Route key={PAGES.SERVICEINQUIRY} path={PAGES.SERVICEINQUIRY} element={<ServiceInquiry />} />
            <Route key={PAGES.APPLICATIONINQUIRY} path={PAGES.APPLICATIONINQUIRY} element={<SolutionInquiry />} />
            <Route key={PAGES.TRAFFICINQUIRY} path={PAGES.TRAFFICINQUIRY} element={<TrafficInquiry />} />
            <Route key={PAGES.MONEYINQUIRY} path={PAGES.MONEYINQUIRY} element={<MoneyInquiry />} />
            <Route key={PAGES.BUSINESSINQUIRY} path={PAGES.BUSINESSINQUIRY} element={<BusinessInquiry />} />
          </Route>
        </Route>
        <Route index element={null}></Route>
      </Route >
    ),
  }
]

export const ALL_ACTIONS: IAction[] = [
  { name: ACTIONS.LANG_KO, element: <SetLang lang="ko" /> },
  { name: ACTIONS.LANG_EN, element: <SetLang lang="en" /> },
]

export const mainMenuFullTree = [
  { name: PAGES.HOME},
  { name: PAGES.DATASPACE },
  { name: PAGES.ONBOARDING },
  /*{ name: PAGES.PARTNER },
  { name: PAGES.SERVICE },
  { name: PAGES.USE_CASE },
  { name: PAGES.SUPPORT },*/
]

export const subMenuFull = [
  PAGES.INQUIRY
]

export const footerMenuFull = [
  PAGES.PRIVACY,
  PAGES.TERMS,
]