import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../shared/frame/Typography';
import './list.scss'

interface PartnerItemProps {
    partneritem: PartnerItem
}

function Governance({ partneritem }: PartnerItemProps) {
    const { t, i18n } = useTranslation();
    const coreitems = partneritem.item?.[i18n.language as keyof typeof partneritem.item] || [];
    const contentItems = coreitems.filter((item) => item);
    const roles: string[] = ['certification', 'idsa']
    return (
        <main>
            {contentItems.map((item, index) => (
                <div key={index} className="gridbox">
                    {item.governance && (
                        <>
                            {roles.map((role => {
                                return (
                                    <div key={role} className='partnerbox'>
                                        <Typography variant="h5">
                                            {item.governance && (item.governance as any)[role]?.rolename}
                                        </Typography>
                                        <hr />
                                        <Typography variant="body1">
                                            {item.governance && (item.governance as any)[role]?.roledesc}
                                        </Typography>
                                    </div>
                                )
                            }))}
                        </>
                    )}
                </div>
            ))}
        </main>
    )
}

export default Governance
