import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MainHeader } from "../../shared/frame/MainHeader/MainHeader";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import usecaseitem from "../../../item/usecaseitem.json";
import { Link, useParams } from "react-router-dom";
import './UseCase.scss'

export default function UseCase() {
  const { t, i18n } = useTranslation();
  const { searchKeyword } = useParams();

  const [currentCategory, setCurrentCategory] = useState<string>('all');
  const [searchTerm] = useState<string>(searchKeyword || '');

  const UsecaseItem = usecaseitem.item?.[i18n.language as keyof typeof usecaseitem.item] || [];
  const categories = Array.from(new Set(UsecaseItem.map(item => item.category)));

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentCategory(newValue);
  };

  return (
    <main className="useCase">
      <MainHeader
        title={t("pages.usecase")}
        headerHeight={200}
        subTitleWidth={1000}
        titleIcon={<BrandingWatermarkIcon className="header-icon" />}
      />
      <Tabs value={currentCategory} onChange={handleTabChange} centered>
        <Tab label={t('content.usecase.categories.all')} value="all" sx={{ color: "#cccccc" }} />
        {categories.map(category => (
          <Tab key={category} label={t(`content.usecase.categories.${category}`)} value={category} sx={{ color: "#cccccc" }} />
        ))}
      </Tabs>
      <section>
        <Box className='item'>
          {UsecaseItem
            .filter((item: Usecase) => currentCategory === 'all' || item.category === currentCategory)
            .map((item: Usecase, index: number) => (
              <Link to={`/usecase/${item.id}`} key={index}>
                <Box className='usecase-box'>
                  <div style={{ padding: '20px' }}>
                    <div className="image">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="text">
                      <Typography variant="h5" sx={{ fontWeight: 'bolder' }}>{item.title}</Typography>
                      <div className='usecase-content'>
                        <Typography variant="body1">
                          {item.description}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Box>
              </Link>
            ))}
        </Box>
      </section>
    </main>
  );
}
