import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import '../inquiry.scss'
import { useTranslation } from "react-i18next";
import { Typography } from "../../../shared/frame/Typography";
import { useState } from "react";
import { Dialog } from "../../../shared/basic/Dialog";
import { DialogContent } from "../../../shared/basic/Dialog/DialogContent";
import { DialogActions } from "../../../shared/basic/Dialog/DialogActions";
import { DialogHeader } from "../../../shared/basic/Dialog/DialogHeader";
import Inquiry from "..";
import { Button, LoadingButton } from "@quantumsurf/portal-shared-components";
import RestService from "../../../../services/RestService";
import { useForm } from "react-hook-form";
import { MailData } from "../../../../feature/models/mailData";
import { error } from "console";

export default function JoinInquriy() {
    const { t } = useTranslation("", {
        keyPrefix: "content.inquiry",
    })
    const margin = { mt: 4, mb: 2, mr: -2, ml: -2 }
    const selectList = ["앱 공급자", "앱 소비자", "서비스 공급자", "서비스 소비자"]
    const privacy = ['privacy']

    const [hasError, setHasError] = useState<boolean>(false);
    const [subtype, setSubType] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [accept, setAccept] = useState({
        privacy: false
    })

    const [openModal, setOpenModal] = useState<string | null>(null);
    const [openMessage, setOpenMessage] = useState<string | null>(null);
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "onChange"
    });


    const handleSubType = (e: any) => {
        setSubType(e.target.value as string);
        setValue("subType", e.target.value);
        setHasError(false);
    };

    const handleModalOpen = (modalType: string) => {
        setOpenModal(modalType);
    };

    const handleModalClose = () => {
        setOpenModal(null);
    };

    const hasErrors = (key: string) => {
        return errors[key] == undefined ? true : false;
    }

    return (
        <main className="inquiry">
            <Inquiry />
            <Box className='inquirybox'>
                <form onSubmit={handleSubmit((data) => {
                    if(accept.privacy == false){
                        setOpenMessage('privacy_failure');
                        return;
                    }
                    if(isLoading) return;
                    setIsLoading(true);
                    data.type = "1";
                    RestService.sendMail(data).then((req) => {
                        if (req.data.code === 200) {
                            setOpenMessage('join_request_success');
                        } else {
                            setOpenMessage('join_request_failure');
                        }
                    }).catch((err) => {
                        setOpenMessage('join_request_failure');
                    }).finally(() => {
                        setIsLoading(false);
                    })
                })}>
                    <InputLabel className="inquiryLabel">
                        <Typography className="inquirytitle">{t('inquiry.company')}
                            <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <FormControl error={hasErrors('companyName')} fullWidth>
                            <TextField fullWidth placeholder={t('inquiry.companyplaceholder')} {...register("companyName", { required: true })}></TextField>
                            {errors.companyName && <FormHelperText>{t('inquiry.companyerror')}</FormHelperText>}
                        </FormControl>
                    </InputLabel>
                    <InputLabel className="inquiryLabel">
                        <Typography className="inquirytitle">{t('inquiry.name')}
                            <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <FormControl error={hasErrors('name')} fullWidth>
                            <TextField fullWidth placeholder={t('inquiry.nameplaceholder')} {...register("name", { required: true })} name="name"></TextField>
                            {errors.name && <FormHelperText>{t('inquiry.nameerror')}</FormHelperText>}
                        </FormControl>
                    </InputLabel>

                    <Divider sx={{ ...margin }} />

                    <InputLabel className="inquiryLabel">
                        <Typography className="inquirytitle">{t('inquiry.jointype')}
                            <span style={{ color: 'red' }}> *</span>
                        </Typography>

                        <FormControl error={hasErrors('subtype')} fullWidth>
                            <Select
                                displayEmpty
                                value={subtype}
                                onChange={handleSubType}
                                renderValue={(v) => (v?.length ? v : t('inquiry.jointypeplaceholder'))}
                                sx={{ width: '100%', Height: '30px' }}
                            >

                                {selectList.map((item) => (
                                    <MenuItem value={item} key={item} >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.subtype && <FormHelperText>{t('inquiry.typeerror')}</FormHelperText>}
                        </FormControl>
                    </InputLabel>

                    <InputLabel className="inquiryLabel">
                        <Typography className="inquirytitle">{t('inquiry.title')}
                            <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <FormControl error={hasErrors('title')} fullWidth>
                            <TextField fullWidth placeholder={t('inquiry.titleplaceholder')} {...register("title", { required: true })}></TextField>
                            {errors.title && <FormHelperText>{t('inquiry.titleerror')}</FormHelperText>}
                        </FormControl>
                    </InputLabel>

                    <InputLabel className="inquiryLabel">
                        <Typography className="inquirytitle">{t('inquiry.content')}
                            <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <FormControl error={hasErrors('description')} fullWidth>
                            <TextField
                                multiline
                                fullWidth
                                rows={10} maxRows={20}
                                placeholder={t('inquiry.contentplaceholder')}
                                {...register("description", {
                                })}></TextField>
                            {errors.description && <FormHelperText>{t('inquiry.contenterror')}!</FormHelperText>}
                        </FormControl>
                    </InputLabel>

                    <Divider sx={{ ...margin }} />

                    <InputLabel className="inquiryLabel">
                        <Typography className="inquirytitle">{t('inquiry.address')}
                            <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <FormControl error={hasErrors('')} fullWidth>
                            <TextField fullWidth placeholder={t('inquiry.addressplaceholder')}   {...register("email", {
                                required: true,
                                pattern: /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/
                            })}></TextField>
                            {errors.email && <FormHelperText>{t('inquiry.addresserror')}</FormHelperText>}
                        </FormControl>
                    </InputLabel>

                    <InputLabel className="inquiryLabel">
                        <Typography className="inquirytitle">{t('inquiry.phone')}
                            <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <FormControl error={hasErrors('phone')} fullWidth>
                            <TextField fullWidth placeholder={t('inquiry.phoneplaceholder')}  {...register("phone", { required: true })} ></TextField>
                            {errors.phone && <FormHelperText sx={{ width: '300px' }}>{t('inquiry.phoneerror')}</FormHelperText>}
                        </FormControl>
                    </InputLabel>

                    <Divider sx={{ ...margin }} />

                    <InputLabel className="inquiryLabel">
                        <Typography className="inquirytitle">
                            {t('inquiry.term')}
                            <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <FormControl error={hasError} fullWidth>
                            <FormGroup>
                                {privacy.map((modalType) => (
                                    <div className="termbox" key={modalType}>
                                        <FormControlLabel
                                            control={<Checkbox value={accept.privacy} onChange={
                                                (e: any) => {
                                                    setAccept((accept) => { return { ...accept, privacy: e.target.checked } })
                                                }
                                            } />}
                                            label={t(`inquiry.termcheckbox.${modalType}.title`)}
                                            sx={{ color: 'black' }}
                                        />
                                        <Button variant="outlined" onClick={() => handleModalOpen(modalType)}>
                                            {t('inquiry.termcontent')}
                                        </Button>
                                    </div>
                                ))}
                            </FormGroup>
                            {hasError && <FormHelperText sx={{ width: '300px' }}>{t('inquiry.phoneerror')}</FormHelperText>}
                        </FormControl>
                    </InputLabel>

                    <Divider sx={{ ...margin }} />

                    <LoadingButton type="submit" size="medium" label={t('join_send')} loadIndicator="" loading={isLoading} onButtonClick={() => { }}
                        sx={{
                            display: 'flex',
                            margin: '0px auto',
                        }} />
                    <Dialog open={!!openModal}>
                        {openModal && (
                            <>
                                <DialogHeader>
                                    <Typography variant="h5" sx={{ color: 'white' }}>{t(`inquiry.termcheckbox.${openModal}.title`)}</Typography>
                                </DialogHeader>
                                <DialogContent>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{t(`inquiry.termcheckbox.${openModal}.subtitle`)}</Typography>
                                    <Box sx={{ margin: '20px' }}>
                                        {[1, 2, 3].map((section) => {
                                            const titleKey = `inquiry.termcheckbox.${openModal}.content${section}.title`;
                                            const contentItems = [1, 2].map((item) => t(`inquiry.termcheckbox.${openModal}.content${section}.item${item}`));

                                            if (!t(`inquiry.termcheckbox.${openModal}.content${section}.title`)) {
                                                return null;
                                            }

                                            return (
                                                <div key={section}>
                                                    <Typography variant="h6" sx={{ fontWeight: 'bolder', fontSize: '15px' }}>{t(titleKey)}</Typography>
                                                    {(contentItems.some(item => item.trim() !== '')) && (
                                                        <ul className="termlist">
                                                            {contentItems.map((item, index) => (
                                                                item.trim() !== '' && <li key={index}>{item}</li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </Box>
                                    <Typography variant="h6" sx={{ fontWeight: 'bolder', fontSize: '15px', margin: '20px' }}>
                                        {t(`inquiry.termcheckbox.${openModal}.etc`)}
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleModalClose} color="primary">
                                        {t('inquiry.close')}
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                    </Dialog>
                    <Dialog open={!!openMessage}>
                        {openMessage && (
                            <>
                                <DialogHeader>
                                    <Typography variant="h5" sx={{ color: 'white' }}>{t(`message.${openMessage}.title`)}</Typography>
                                </DialogHeader>
                                <DialogContent>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{t(`message.${openMessage}.subtitle`)}</Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setOpenMessage(null)} color="primary">
                                        {t('message.close')}
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                    </Dialog>
                </form>
            </Box>
        </main >
    )
}
