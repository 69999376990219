import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';

interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function Bpdmsolution({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.bpdm && (
                        <>
                            <Typography variant='h4'>{item.content.bpdm.solution?.title}</Typography>
                            <Typography variant='h6'>{item.content.bpdm.solution?.description}</Typography>
                            <img src={item.content.bpdm.solution?.img} alt={item.content.bpdm.solution?.subtitle} className='step-img' />
                            {['gate', 'pool', 'issuer', 'orchestrator'].map((category) => {
                                const dynamicContent = item.content?.bpdm?.solution?.[category as keyof typeof item.content.bpdm.solution];

                                if (dynamicContent && typeof dynamicContent === 'object' && 'title' in dynamicContent) {

                                    return (
                                        <div key={category} style={{ margin: '10px 0px' }}>
                                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                                                {dynamicContent.title}
                                            </Typography>
                                            {Object.entries(dynamicContent.list || {}).map(([key, value]) => (
                                                <Typography key={key} variant="body1">
                                                    <li style={{ margin: '10px 0px' }}>
                                                        {value}
                                                    </li>
                                                </Typography>
                                            ))}
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </>
                    )}
                </div>
            ))}
        </section>
    )
}

export default Bpdmsolution