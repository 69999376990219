import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';
import { Divider } from '@mui/material';
import formatcode from '../../../../../../assets/image/verificationcode.png'
interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function MIWVerfifcation({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.wallet && (
                        <>
                            <Typography variant='h4'>{item.content?.wallet?.verification?.title}</Typography>
                            <div className="format">
                                <Typography variant='h6'>{item.content?.wallet?.verification.format?.title}</Typography>
                                <Typography variant='body1'>{item.content?.wallet?.verification.format?.description}</Typography>
                                <Typography variant='body1'>{item.content?.wallet?.verification.format?.example}</Typography>
                                <img className='step-img' src={formatcode} alt="formatcode" />
                            </div>
                            <Divider sx={{ margin: '10px', border: 'none' }} />
                            <div className="vpandvc">
                                <Typography variant='h6'>{item.content?.wallet?.verification.vpandvc?.title}</Typography>
                                <Typography variant='body1'>{item.content?.wallet?.verification.vpandvc?.description}</Typography>
                                <Typography variant='body1'>{item.content?.wallet?.verification.vpandvc?.justice}</Typography>
                                <Typography variant='body1'>{item.content?.wallet?.verification.vpandvc?.vpsupport}</Typography>
                                <div className="links">
                                    <Typography variant='body1'>{item.content?.wallet?.verification.vpandvc?.list?.behaviortwin}<a href={item.content?.wallet?.verification.vpandvc?.list?.behaviortwinlink}>{item.content?.wallet?.verification.vpandvc?.list?.behaviortwinlink}</a></Typography>
                                    <Typography variant='body1'>{item.content?.wallet?.verification.vpandvc?.list?.bpn}<a href={item.content?.wallet?.verification.vpandvc?.list?.bpnlink}>{item.content?.wallet?.verification.vpandvc?.list?.bpnlink}</a></Typography>
                                    <Typography variant='body1'>{item.content?.wallet?.verification.vpandvc?.list?.dismantler}<a href={item.content?.wallet?.verification.vpandvc?.list?.dismantlerlink}>{item.content?.wallet?.verification.vpandvc?.list?.dismantlerlink}</a></Typography>
                                    <Typography>{item.content?.wallet?.verification.vpandvc?.list?.usecaseframeworkcondition}</Typography>
                                    <ul style={{ padding: '10px 40px', margin: '0px' }}>
                                        <Typography variant='body1'><li>{item.content?.wallet?.verification.vpandvc?.list?.pcf}<a href={item.content?.wallet?.verification.vpandvc?.list?.pcflink}>{item.content?.wallet?.verification.vpandvc?.list?.pcflink}</a></li></Typography>
                                        <Typography variant='body1'><li>{item.content?.wallet?.verification.vpandvc?.list?.quality}<a href={item.content?.wallet?.verification.vpandvc?.list?.qualitylink}>{item.content?.wallet?.verification.vpandvc?.list?.qualitylink}</a></li></Typography>
                                        <Typography variant='body1'><li>{item.content?.wallet?.verification.vpandvc?.list?.resiliency}<a href={item.content?.wallet?.verification.vpandvc?.list?.resiliencylink}>{item.content?.wallet?.verification.vpandvc?.list?.resiliencylink}</a></li></Typography>
                                        <Typography variant='body1'><li>{item.content?.wallet?.verification.vpandvc?.list?.sustainability}<a href={item.content?.wallet?.verification.vpandvc?.list?.sustainabilitylink}>{item.content?.wallet?.verification.vpandvc?.list?.sustainabilitylink}</a></li></Typography>
                                        <Typography variant='body1'><li>{item.content?.wallet?.verification.vpandvc?.list?.trace}<a href={item.content?.wallet?.verification.vpandvc?.list?.tracelink}>{item.content?.wallet?.verification.vpandvc?.list?.tracelink}</a></li></Typography>
                                        <Typography variant='body1'><li>{item.content?.wallet?.verification.vpandvc?.list?.behaviortwin}<a href={item.content?.wallet?.verification.vpandvc?.list?.behaviortwinlink}>{item.content?.wallet?.verification.vpandvc?.list?.behaviortwinlink}</a></li></Typography>
                                    </ul>
                                    <Typography variant='h6'>{item.content?.wallet?.verification.vpandvc?.signed}</Typography>
                                    <Typography>{item.content?.wallet?.verification.vpandvc?.signeddesc}</Typography>
                                </div>
                            </div>
                            <Divider sx={{ margin: '10px', border: 'none' }} />
                            <div className="verifiable">
                                <Typography variant='h6'>{item.content?.wallet?.verification.verifiable?.title}</Typography>
                                <Typography variant='body1'>{item.content?.wallet?.verification.verifiable?.description}</Typography>
                                <Typography variant='body1'>{item.content?.wallet?.verification.verifiable?.description1}</Typography>
                                <Typography variant='body1'>{item.content?.wallet?.verification.verifiable?.desc}</Typography>
                                <Divider sx={{ margin: '10px', border: 'none' }} />
                                <div className="query">
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>{item.content?.wallet?.verification.verifiable?.query?.title}</Typography>
                                    <Typography variant='body1'>{item.content?.wallet?.verification.verifiable?.query?.src}</Typography>
                                    <Typography variant='body1'>{item.content?.wallet?.verification.verifiable?.query?.desc}</Typography>
                                </div>
                                <Divider sx={{ margin: '10px', border: 'none' }} />
                                <div className="presentation">
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>{item.content?.wallet?.verification.verifiable?.presentation?.title}</Typography>
                                    <Typography variant='body1'>{item.content?.wallet?.verification.verifiable?.presentation?.src}</Typography>
                                    <Typography variant='body1'>{item.content?.wallet?.verification.verifiable?.presentation?.desc}</Typography>
                                </div>
                                <Divider sx={{ margin: '10px', border: 'none' }} />
                                <div className="verify">
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>{item.content?.wallet?.verification.verifiable?.verify?.title}</Typography>
                                    <Typography variant='body1'>{item.content?.wallet?.verification.verifiable?.verify?.src}</Typography>
                                    <Typography variant='body1'>{item.content?.wallet?.verification.verifiable?.verify?.desc}</Typography>
                                </div>
                                <Divider sx={{ margin: '10px', border: 'none' }} />
                                <div className="endpoint">
                                    <Typography variant='h6'>{item.content?.wallet?.verification.verifiable?.endpoint}</Typography>
                                    <Typography variant='body1'>{item.content?.wallet?.verification.verifiable?.endpointdescription}</Typography>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </section>
    )
}


export default MIWVerfifcation;