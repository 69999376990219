import { Box } from '@mui/material'
import React from 'react'
import { ErrorImage } from '../../shared/basic/ErrorPage/components/ErrorImage'
import { Typography } from '../../shared/frame/Typography'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../shared/frame/Button'

export interface ErrorPageProps {
    hasNavigation?: boolean
    header?: string
    title?: string
    description?: string
    additionalDescription?: string
    reloadButtonTitle?: string
    homeButtonTitle?: string
    onReloadClick?: React.MouseEventHandler
    onHomeClick?: React.MouseEventHandler
    color?: "color" | "gray"
}

export const NoData = ({
    hasNavigation,
    header,
    title,
    description,
    additionalDescription,
    reloadButtonTitle,
    homeButtonTitle,
    onReloadClick,
    onHomeClick,
    color = "gray",
}: ErrorPageProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation()
    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            backgroundColor: '#f7f7f7'
        }}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    margin: "auto auto",
                }}
            >
                <Box
                    sx={{
                        minWidth: "400px",
                    }}
                >
                    <ErrorImage variant={color} />
                </Box>
                <Box>
                    <Typography variant='h3'>{t('nodata.title')}</Typography>
                    <Typography sx={{ marginTop: '10px' }}>{t('nodata.subtitle')}</Typography>
                    <Button onClick={() => navigate(-1)} sx={{ margin: '70px 0px' }}>
                        {t('global.back')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
