import { Typography } from "../../../frame/Typography"
import { type SwiperHeaderTitleProps } from "../SwiperHeader"

export const SwiperHeaderTitle = ({
  title,
  titleStyle,
}: SwiperHeaderTitleProps) => {
  return (
    <>
      {title && (
        <Typography
          variant="h4"
          style={titleStyle}
        >
          {title}
        </Typography>
      )}
    </>
  )
}
