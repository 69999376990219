import React, { useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    Input,
    Pagination,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import { Typography } from "../../../shared/frame/Typography";
import { useTranslation } from "react-i18next";
import faqitem from "../../../../item/faqitem.json";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import "./Faq.scss";
import { MainHeader } from "../../../shared/frame/MainHeader/MainHeader";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { Button } from "../../../shared/frame/Button";
interface FaqItem {
    id: string;
    title: string;
    description: string;
    category: string;
}

interface LanguageItem {
    en: FaqItem[];
    ko: FaqItem[];
}

export default function Faq() {
    const { t, i18n } = useTranslation();
    const { searchKeyword } = useParams<{ searchKeyword?: string }>();
    const [expanded, setExpanded] = useState<string | false>('panel1');
    const [searchTerm, setSearchTerm] = useState<string>(searchKeyword || '');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 10;
    const navigate = useNavigate()
    // faqitem과 언어별 항목이 존재하는지 확인합니다.
    const languageItems = faqitem.item?.[i18n.language as keyof LanguageItem] || [];

    // categories를 생성할 때 존재 여부를 확인합니다.
    const categoriesSet = new Set(languageItems.map((item) => item.category));
    const categories = Array.from(categoriesSet);

    const [currentCategory, setCurrentCategory] = useState<string>('all');

    // filteredItems를 여기서 선언합니다.
    const filteredItems = languageItems.filter(
        (item) =>
            (currentCategory === 'all' || item.category === currentCategory) &&
            (item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.description.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setCurrentCategory(newValue);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
    };


    const link = () => {
        navigate('/support/faq/inquiry/service')
    }
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return (
        <main>
            <MainHeader title={t('pages.faq')} headerHeight={200} titleIcon={<HelpCenterIcon />} />
            <section>
                <Box className="faqbox">
                    <Typography variant="h4" sx={{ marginBottom: '30px' }}>{t('faq.desc')}</Typography>
                    <div style={{ display: 'flex' }}>
                        <Input
                            placeholder={t('search.title')}
                            fullWidth
                            value={searchTerm}
                            onChange={handleSearchChange}
                            sx={{ height: '60px' }}
                        />
                        <Button
                            className="linkbutton"
                            onClick={() => {
                                link()
                            }}
                        >
                            {t('global.notice')}
                        </Button>
                    </div>
                    <div style={{ display: 'flex', margin: '10px' }} >
                        <Tabs
                            value={currentCategory}
                            onChange={handleTabChange}
                            textColor="primary"
                            indicatorColor="primary"
                            centered
                        >
                            <Typography sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                {t('faq.keyword')}
                            </Typography>
                            <Tab label={t('faq.categories.all')} value="all" />
                            {categories.map(category => (
                                <Tab key={category} label={t(`faq.categories.${category}`)} value={category} className="faqtab" />
                            ))}
                        </Tabs>
                    </div>
                    {filteredItems.slice(startIndex, endIndex).map((item, id) => (
                        <Accordion
                            className="accordion"
                            expanded={expanded === `panel_${id}`}
                            onChange={handleChange(`panel_${id}`)}
                            key={item.id}
                        >
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography variant="h6" className="faqtitle">
                                    {item.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ backgroundColor: '#f9f9f9', padding: '16px 16px', fontSize: '15px' }}>
                                <Typography>
                                    {item.description}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}

                    <Pagination
                        count={Math.ceil(filteredItems.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        sx={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}
                    />
                </Box>
            </section>
        </main>
    );
};

