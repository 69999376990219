import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';
interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function SdFactoryfeature({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const renderParticipantInfo = (role: any) => {
        return (
            <>
                <Typography variant='body1' sx={{ display: 'flex' }}>
                    <li>{role.headquarter}</li>
                    <Typography variant='body1' marginLeft={'10px'}>
                        <a href={role.link}>{role.isolink}</a>
                        {role.headquarterdesc}
                    </Typography>
                </Typography>
                <Typography variant='body1' sx={{ display: 'flex' }}>
                    <li>{role.legaladdress}</li>
                    <Typography variant='body1' marginLeft={'10px'}>
                        <a href={role.link}>{role.isolink}</a>
                        {role.legaladdressdesc}
                    </Typography>
                </Typography>
                <Typography variant='body1' sx={{ display: 'flex' }}>
                    <li>{role.registrationnumber}</li>
                    <Typography variant='body1' marginLeft={'10px'}>
                        {role.registrationnumberdesc}
                    </Typography>
                </Typography>
            </>

        );
    }
    return (
        <section className='service-feature'>
            {serviceitem.item?.[i18n.language as keyof typeof serviceitem.item]?.map((item) => (
                item.id === selectedItemId &&
                item.content?.title && (
                    <>
                        <div key={item.id} className="sdfactory">
                            <Typography variant='h4'>{item.content.sdfactory?.title}</Typography>
                            {item.content.sdfactory?.participant && (
                                <>
                                    <Typography variant='h6' sx={{ fontWeight: 'bold' }}>{item.content.sdfactory.participant.title}</Typography>
                                    {renderParticipantInfo(item.content.sdfactory.participant.role)}
                                </>
                            )}
                        </div>
                        < div style={{ marginTop: '20px' }} >
                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>{item.content.sdfactory?.participant?.tool}</Typography>
                            <Typography variant='body1'><li>{item.content.sdfactory?.participant?.bpn}</li></Typography>

                        </div>
                        < div style={{ marginTop: '20px' }} >
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{item.content?.sdfactory?.participant?.servicetitle}</Typography>
                            {Object.entries(item.content?.sdfactory?.participant?.service || {}).map(([key, value]) => (
                                <>
                                    <Typography variant="body1">
                                        <li key={item.id} style={{ marginTop: '10px' }}>
                                            {value}
                                        </li>
                                    </Typography>
                                </>
                            ))}
                        </div>
                        < div style={{ marginTop: '20px' }} >
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{item.content?.sdfactory?.participant?.gx?.title}</Typography>
                            <Typography variant="body1">
                                {item.content?.sdfactory?.participant?.gx?.title}
                                (<a href={item.content?.sdfactory?.participant?.gx?.link}>{item.content?.sdfactory?.participant?.gx?.link}</a>)
                            </Typography>
                        </div>
                    </>
                )
            ))}
        </section>
    );
}

export default SdFactoryfeature;
