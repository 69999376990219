// I18nService.ts
import i18n, { changeLanguage } from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import mainKR from "../assets/locales/kr/main.json";
import footerKR from "../assets/locales/kr/footer.json";
import mainEN from "../assets/locales/en/main.json";
import footerEN from "../assets/locales/en/footer.json";
import dataspaceKR from "../assets/locales/kr/dataspace.json";
import dataspaceEN from "../assets/locales/en/dataspace.json";

const resources = {
  en: {
    translation: mainEN,
    footer: footerEN,
    dataspace: dataspaceEN,
  },
  ko: {
    translation: mainKR,
    footer: footerKR,
    dataspace: dataspaceKR,
  },
};

const supportedLanguages = Object.keys(resources).sort((a, b) =>
  a.localeCompare(b)
);

const init = (): void => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      fallbackLng: "ko",
      supportedLngs: ["ko", "en"],
      interpolation: {
        escapeValue: false,
      },
    })
    .catch((e) => console.error("Translation library init got error:", e));
};

const I18nService = {
  init,
  changeLanguage,
  useTranslation,
  supportedLanguages,
};

export default I18nService;
