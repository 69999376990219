import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';
import { Divider } from '@mui/material';
import identityimg from '../../../../../../assets/image/bpndid.png'

interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function MIWoverview({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.wallet && (
                        <>
                            <div className="intro">
                                <Typography variant='h4'>{item.content?.wallet?.miw?.title}</Typography>
                                <Typography variant='h6'>{item.content?.wallet?.miw?.subtitle}</Typography>
                                <Typography variant='body1'>{item.content?.wallet?.miw?.description}</Typography>
                            </div>
                            <Divider sx={{ margin: '10px', border: 'none' }} />
                            <div className="request">
                                <Typography variant='h4'>{item.content?.wallet?.request?.title}</Typography>
                                <Typography variant='h6'>{item.content?.wallet?.request?.desc}</Typography>
                                {Object.entries(item.content?.wallet?.request?.requestlist || {}).map(([key, value]) => (
                                    <div>
                                        <Typography variant="body1">{value}</Typography>
                                    </div>
                                ))}
                            </div>
                            <Divider sx={{ margin: '10px', border: 'none' }} />
                            <div className="request">
                                <Typography variant='h4'>{item.content?.wallet?.identity?.title}</Typography>
                                <Typography variant='body1'>{item.content?.wallet?.identity?.description}</Typography> <br />
                                <Typography variant='body1'>{item.content?.wallet?.identity?.description1}</Typography> <br />
                                <img src={identityimg} alt="identityimg" className='step-img' />
                                <Typography variant='body1'>{item.content?.wallet?.identity?.description2}</Typography>
                            </div>
                            <Divider sx={{ margin: '10px', border: 'none' }} />
                            <div className="request">
                                <Typography variant='h4'>{item.content?.wallet?.limit?.title}</Typography>
                                <Typography variant='body1'>{item.content?.wallet?.limit?.desc}</Typography>
                                {Object.entries(item.content?.wallet?.limit?.limitlist || {}).map(([key, value]) => (
                                    <div>
                                        <Typography variant="body1">{value}</Typography>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            ))}
        </section>
    )
}


export default MIWoverview;
