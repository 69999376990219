import React from 'react'
import { MainHeader } from '../../shared/frame/MainHeader/MainHeader'
import { useTranslation } from 'react-i18next'
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import ServiceTab from './components/servicetab';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../shared/frame/Button';

export default function Service() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const link = () => {
        navigate('/support/faq/inquiry/service')
    }
    return (
        <main>
            <MainHeader
                title={t('pages.service')}
                headerHeight={200}
                titleIcon={<SettingsSuggestOutlinedIcon />}
            />
            <ServiceTab />
        </main>
    )
}
