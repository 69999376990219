import StageSection from "./components/StageSection";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import NoticeSection from "./components/NoticeSection";
import ServiceSection from "./components/ServiceSection";
import ApplicationFieldSection from "./components/ApplicationFieldSection";
import CompanySection from "./components/CompanySection";
import IntroduceSection from "./components/IntroduceSection";
import VisionSection from "./components/VisionSection";
import PrinciplesSection from "./components/Principles";

export default function Home() {
  return (
    <main className="home">
      {/* {<StageSection />} */}
      <IntroduceSection />
      <PrinciplesSection />
      {/* {<VisionSection />} */}
      {/* {<NoticeSection />} */}
      <ServiceSection />
      <ApplicationFieldSection />
      {/* {<CompanySection />} */}
    </main>
  )
}
