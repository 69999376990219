import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';
import { Divider } from '@mui/material';

interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function Idpmanagement({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.idp && (
                        <>
                            <Typography variant='h4'> {item.content?.idp.management?.title}</Typography>
                            <Typography variant='body1'> {item.content?.idp.management?.description}</Typography>
                            <br />
                            <Typography variant='body1'> {item.content?.idp.management?.evenmore}</Typography>
                            <Divider sx={{ margin: '10px', border: 'none' }} />
                            <div className="technical">
                                <Typography variant='h6'> {item.content?.idp.management?.technical?.title}</Typography>
                                <Typography variant='body1'> {item.content?.idp.management?.technical?.subtitle}</Typography>
                                {Object.entries(item.content?.idp?.management?.technical?.list || {}).map(([key, value]) => (
                                    <>
                                        <Typography variant="body1"><li>{value}</li></Typography>
                                    </>
                                ))}
                                <Typography variant='body1'> {item.content?.idp.management?.technical?.desc}</Typography>
                                <Divider sx={{ margin: '5px', border: 'none' }} />
                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}> {item.content?.idp.management?.technical?.role}</Typography>
                                <Typography variant='body1'> {item.content?.idp.management?.technical?.roledesc}</Typography>
                            </div>
                            <Divider sx={{ margin: '10px', border: 'none' }} />
                            <div className="atrributes">
                                <Typography variant='h6'> {item.content?.idp.management?.atrributes?.title}</Typography>
                                <Typography variant='body1'> {item.content?.idp.management?.atrributes?.subtitle}</Typography>
                                {Object.entries(item.content?.idp?.management?.atrributes?.list || {}).map(([key, value]) => (
                                    <>
                                        <Typography variant="body1"><li>{value}</li></Typography>
                                    </>
                                ))}
                                <Divider sx={{ margin: '10px', border: 'none' }} />
                                <div className="bpnmapper">
                                    <Typography variant='h6'>{item.content?.idp.management?.atrributes?.bpnmapper}</Typography>
                                    <Typography variant='body1'>{item.content?.idp.management?.atrributes?.bpnmapperdesc}</Typography>
                                    {Object.entries(item.content?.idp?.management?.atrributes?.bpnmaepperoption || {}).map(([key, value]) => (
                                        <>
                                            <Typography variant="body1"><li>{value}</li></Typography>
                                        </>
                                    ))}
                                </div>
                                <Divider sx={{ margin: '10px', border: 'none' }} />
                                <div className="usernamemapper">
                                    <Typography variant='h6'>{item.content?.idp.management?.atrributes?.usernamemapper}</Typography>
                                    <Typography variant='body1'>{item.content?.idp.management?.atrributes?.usernamemapperdesc}</Typography>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </section>
    )
}

export default Idpmanagement