import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';

interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function Sdfactoryrest({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.sdfactory && (
                        <>
                            <Typography variant='h4'>{item.content.sdfactory?.solution?.step4.title}</Typography>
                            <Typography sx={{ marginBottom: '30px' }}>{item.content.sdfactory?.solution?.step4.desc}</Typography>


                            <Typography variant='h4'>{item.content.sdfactory?.solution?.step5.title}</Typography>
                            {Object.entries(item.content?.sdfactory?.solution?.step5.list || {}).map(([key, value]) => (
                                <>
                                    <div key={item.id}>
                                        <Typography variant="body1">
                                            <li style={{ listStyle: 'none' }}>{value}</li>
                                        </Typography>
                                    </div>
                                </>
                            ))}
                        </>
                    )}
                </div>
            ))}
        </section>
    )
}

export default Sdfactoryrest