import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp';
import { useTranslation } from 'react-i18next';
import './../service.scss';
import serviceitem from '../../../../item/serviceitem.json';

export default function ServiceTab() {
    const { t, i18n } = useTranslation();
    const { searchKeyword } = useParams();
    const [searchTerm] = useState<string>(searchKeyword || '');
    const [currentPage] = useState<number>(1);
    const itemsPerPage = 10;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const [currentCategory, setCurrentCategory] = useState<string>('all');
    const serviceItemsForLanguage: Item[] | undefined = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item];
    const filteredItems = serviceItemsForLanguage?.filter(
        (item: Item) =>
            (currentCategory === 'all' || item.category === currentCategory) &&
            (item.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.description?.toLowerCase().includes(searchTerm.toLowerCase()))
    ) || [];

    return (
        <div className='service-tab'>
            <section style={{ padding: '12px' }}>
                <div className="service-panel">
                    {filteredItems.slice(startIndex, endIndex).map((item: Item, index: number) => (
                        <Link to={`/service/${item.id}`} key={item.id}>
                            <Box className='service-box'>
                                <div style={{ padding: '20px' }}>
                                    <Typography variant="h5" sx={{ fontWeight: 'bolder' }}>{item.title}</Typography>
                                    <div className='service-content'>
                                        <Typography variant="h6">
                                            {item.description}
                                        </Typography>
                                    </div>
                                    <Link to={`/service/${item.id}`} key={item.id} className='link'>
                                        {t('content.service.detail')}<ChevronRightSharpIcon />
                                    </Link>
                                </div>
                            </Box>
                        </Link>
                    ))}
                </div>
            </section>
        </div>
    );
}
