import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';
import { Divider } from '@mui/material';
interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function MIWTokken({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.wallet && (
                        <>
                            <Typography variant='h4'>{item.content?.wallet?.tokken?.title}</Typography>
                            <div className="step1">
                                <Typography variant='h6'>{item.content?.wallet?.tokken?.step1?.title}</Typography>
                                {Object.entries(item.content?.wallet?.tokken?.step1?.list || {}).map(([key, value]) => (
                                    <div>
                                        <Typography variant="body1"><li>{value}</li></Typography>
                                    </div>
                                ))}
                            </div>
                            <Divider sx={{ margin: '10px', border: 'none' }} />
                            <div className="step2">
                                <Typography variant='h6'>{item.content?.wallet?.tokken?.step2?.title}</Typography>
                                {Object.entries(item.content?.wallet?.tokken?.step2?.list || {}).map(([key, value]) => (
                                    <div>
                                        <Typography variant="body1"><li>{value}</li></Typography>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            ))}
        </section>
    )
}


export default MIWTokken;