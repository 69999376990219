import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';
import { Divider } from '@mui/material';


interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function DigitalTwinfunction({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.digitaltwin && (
                        <>
                            <div className="digitaltwin">
                                <Typography variant='h4'>{item.content?.digitaltwin?.step3?.title}</Typography>
                                <Typography variant='body1'>{item.content?.digitaltwin?.step3?.ass}</Typography>
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '20%', minWidth: '100px' }}>{item.content?.digitaltwin?.step3?.table?.actor}</th>
                                            <th style={{ width: '50%', minWidth: '250px' }}>{item.content?.digitaltwin?.step3?.table?.desc}</th>
                                            <th style={{ width: '30%', minWidth: '100px' }}>{item.content?.digitaltwin?.step3?.table?.example}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{item.content?.digitaltwin?.step3?.table?.provider?.actor}</td>
                                            <td>{item.content?.digitaltwin?.step3?.table?.provider?.desc}</td>
                                            <td>{item.content?.digitaltwin?.step3?.table?.provider?.example}</td>
                                        </tr>
                                        <tr>
                                            <td>{item.content?.digitaltwin?.step3?.table?.consumer?.actor}</td>
                                            <td>{item.content?.digitaltwin?.step3?.table?.consumer?.desc}</td>
                                            <td>{item.content?.digitaltwin?.step3?.table?.consumer?.example}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Typography variant='h6'>{item.content?.digitaltwin?.step3?.desc}</Typography>
                                {Object.entries(item.content?.digitaltwin?.step3?.edc || {}).map(([key, value]) => (
                                    <div>
                                        <Typography variant="body1"><li>{value}</li></Typography>
                                    </div>
                                ))}
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Typography variant='h6'>{item.content?.digitaltwin?.step3?.provider?.title}</Typography>
                                <Typography variant='body1'>{item.content?.digitaltwin?.step3?.provider?.desc}</Typography>
                                {Object.entries(item.content?.digitaltwin?.step3?.provider?.list || {}).map(([key, value]) => (
                                    <div>
                                        <Typography variant="body1"><li>{value}</li></Typography>
                                    </div>
                                ))}
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Typography variant='h6'>{item.content?.digitaltwin?.step3?.provider?.termtitle}</Typography>
                                <Typography variant='body1'>{item.content?.digitaltwin?.step3?.provider?.termdesc}</Typography>
                                {Object.entries(item.content?.digitaltwin?.step3?.provider?.term || {}).map(([key, value]) => (
                                    <div>
                                        <Typography variant="body1"><li>{value}</li></Typography>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            ))}
        </section>
    )
}

export default DigitalTwinfunction