import { useTranslation } from 'react-i18next';
import './../servicecontent.scss';
import Bpdm from './BPDM';
import DigitalTwin from './DigitalTwinRegistry';
import Datachain from './DataChain';
import Wallet from './MIWallet';
import Sdfactory from './SDFactory';
import IDP from './IDP';

interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function ServiceItemList({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);

    return (
        <main>
            {contentItems.length > 0 && (
                <div className="feature-contents">
                    <div className={`content-card content-card-${contentItems.length}`}>
                        {contentItems.map((item) => (
                            <div key={item.id}>
                                {item.content?.bpdm && (
                                    <div className="bpdm">
                                        <Bpdm serviceitem={serviceitem} selectedItemId={selectedItemId} />
                                    </div>
                                )}

                                {item.content?.digitaltwin && (
                                    <div className="digitaltwin">
                                        <DigitalTwin serviceitem={serviceitem} selectedItemId={selectedItemId} />
                                    </div>
                                )}

                                {item.content?.datachain && (
                                    <div className="datachain">
                                        <Datachain serviceitem={serviceitem} selectedItemId={selectedItemId} />
                                    </div>
                                )}

                                {item.content?.sdfactory && (
                                    <div className="sdfactory">
                                        <Sdfactory serviceitem={serviceitem} selectedItemId={selectedItemId} />
                                    </div>
                                )}

                                {item.content?.wallet && (
                                    <div className="sdfactory">
                                        <Wallet serviceitem={serviceitem} selectedItemId={selectedItemId} />
                                    </div>
                                )}

                                {item.content?.idp && (
                                    <div className="sdfactory">
                                        <IDP serviceitem={serviceitem} selectedItemId={selectedItemId} />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </main >
    );
}

export default ServiceItemList;
