import { useTranslation } from 'react-i18next';
import { Typography } from '../../../shared/frame/Typography';
import './../dataspace.scss'
import { Box } from '@mui/material';

export default function DsFeature() {
    const { t } = useTranslation('dataspace', { keyPrefix: "content.feature" });
    return (
        <section className='feature'>
            <Typography variant='h3'>{t('feature')} </Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
                <Box className='featurebox'>
                    {['share', 'governance', 'sovereignty', 'openness', 'federation', 'transparency'].map((item) => {
                        return (
                            <div key={item} className='card'>
                                <Typography variant='h6' sx={{ color: '#007BFF', fontWeight: 'bold' }}> {t(`card.${item}.title`)}</Typography>
                                <Typography variant='body1' sx={{ marginTop: '15px' }}>{t(`card.${item}.desc`)}</Typography>
                            </div>
                        );
                    })}
                </Box>
            </div>
        </section>
    )
}
