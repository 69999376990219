import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';
import { Divider } from '@mui/material';


interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function Datachainfunction({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.datachain && (
                        <div className="irs">
                            <Typography variant='h4' sx={{ marginBottom: "10px", fontWeight: 'bold' }}>{item.content?.datachain?.function?.irs}</Typography>
                            <Typography variant='h6'>{item.content?.datachain?.function?.datachain?.iterrativetitle}</Typography>
                            {Object.entries(item.content?.datachain?.function?.datachain?.iterrative || {}).map(([key, value]) => (
                                <>
                                    <div key={item.id}>
                                        <Typography variant="body1">{value}</Typography>
                                    </div>
                                </>
                            ))}
                            <Typography variant='h6'>{item.content?.datachain?.function?.datachain?.rescursivetitle}</Typography>
                            {Object.entries(item.content?.datachain?.function?.datachain?.rescursive || {}).map(([key, value]) => (
                                <>
                                    <div key={item.id}>
                                        <Typography variant="body1">{value}</Typography>
                                    </div>
                                </>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </section>
    )
}

export default Datachainfunction