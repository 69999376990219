import { useEffect, useRef, useState } from "react"
import { Box, Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { MainHeader } from "../../shared/frame/MainHeader/MainHeader"
import Overview from "./components/overview"
import Type from "./components/type"
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined"
import "./Partner.scss"

interface CoreItemProps {
  partneritem: PartnerItem
}


function Partner({ partneritem }: CoreItemProps) {
  const { t } = useTranslation()
  const [activeButton, setActiveButton] = useState<string>('');
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const handleScroll = () => {
    setIsFixed(window.scrollY > 275);
  };

  const [, setActivePanel] = useState<string>('overview');

  const sectionRefs = [
    useRef(null),
    useRef(null),
  ];

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const index = sectionRefs.findIndex((ref) => ref.current === entry.target);
        if (index !== -1) {
          setActiveButton(tabButtons[index].key);
        }
      }
    });
  };
  const scrollToSection = (elementRef: React.MutableRefObject<HTMLButtonElement | null>, buttonKey: string, panelKey: string) => {
    setActiveButton(buttonKey);
    setActivePanel(panelKey);

    if (elementRef.current !== null) {
      elementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })

      setActiveButton(buttonKey);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2,
    };
    const observer = new IntersectionObserver(handleIntersection, options);
    sectionRefs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });
    return () => {
      observer.disconnect();
    };
  });

  const tabButtons = [
    { key: 'overview', label: t('content.partner.navigation.overview'), panelKey: 'overview' },
    { key: 'feature', label: t('content.partner.navigation.type'), panelKey: 'feature' },
  ];

  return (
    <main className="partner">
      <MainHeader
        title={t("pages.partner")}
        headerHeight={200}
        subTitleWidth={1000}
        titleIcon={<HandshakeOutlinedIcon />} />
      <div
        className={`dataspace-tab ${isFixed ? 'sticky' : ''}`}
        style={{
          gridTemplateColumns: `repeat(${sectionRefs.length},1fr)`,
        }}
      >
        {tabButtons.map((tabButton, index) => (
          <Button
            key={tabButton.key}
            onClick={() => scrollToSection(sectionRefs[index], tabButton.key, tabButton.panelKey)}
            sx={{
              color: activeButton === tabButton.key ? 'black' : '#cccccc',
              '&:hover': {
                backgroundColor: activeButton === tabButton.key ? 'transparent' : '#f9f9f9',
              },
            }}
          >
            {tabButton.label}
          </Button>
        ))}
      </div>
      {sectionRefs.map((sectionRef, index) => (
        <Box
          key={index}
          ref={sectionRef}
          style={{ paddingTop: isFixed ? '0px' : '0px' }}
        >
          {index === 0 && <Overview />}
          {index === 1 && <Type partneritem={partneritem} />}
        </Box>
      ))}
    </main>
  )
}

export default Partner