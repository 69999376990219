import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';


interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function DatachainFeature({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.datachain && (
                        <div className="datachain">
                            <div>
                                <Typography variant='h4' sx={{ marginBottom: "10px", fontWeight: 'bold' }}>{item.content?.datachain?.title}</Typography>
                                <Typography variant='body1'>{item.content?.datachain?.subtitle}</Typography>
                                <Typography variant='body1'>{item.content?.datachain?.description}</Typography>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </section>
    )
}

export default DatachainFeature