import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../shared/frame/Typography';
import './list.scss'

interface PartnerItemProps {
    partneritem: PartnerItem
}

function Intermediary({ partneritem }: PartnerItemProps) {
    const { t, i18n } = useTranslation();
    const coreitems = partneritem.item?.[i18n.language as keyof typeof partneritem.item] || [];
    const contentItems = coreitems.filter((item) => item);
    const roles: string[] = ['broker', 'clearinghouse', 'identityprovider', 'appstoreprovider', 'vocabularyprovider']
    return (
        <main>
            {contentItems.map((item, index) => (
                <div key={index} className="gridbox">
                    {item.intermediary && (
                        <>
                            {roles.map((role => {
                                return (
                                    <div key={role} className='partnerbox'>
                                        <Typography variant="h5">
                                            {item.intermediary && (item.intermediary as any)[role]?.rolename}
                                        </Typography>
                                        <hr />
                                        <Typography variant="body1">
                                            {item.intermediary && (item.intermediary as any)[role]?.roledesc}
                                        </Typography>
                                        <hr />
                                        <Typography variant="h6">
                                            {item.intermediary && (item.intermediary as any)[role]?.role}
                                        </Typography>
                                        {['desc1', 'desc2', 'desc3', 'desc4'].map((desc =>
                                            <>
                                                <Typography variant="body1">
                                                    {item.intermediary && (item.intermediary as any)[role]?.[desc]}
                                                </Typography>
                                            </>
                                        ))}
                                        <div className="list">
                                            <Typography variant="body1" className="list">
                                                {item.intermediary && (item.intermediary as any)[role]?.listtitle}
                                            </Typography>
                                            {['list1', 'list2', 'list3'].map((list =>
                                                <Typography variant="body2">
                                                    {item.intermediary && (item.intermediary as any)[role]?.[list]}
                                                </Typography>
                                            ))}
                                        </div>
                                    </div>
                                )
                            }))}
                        </>
                    )}
                </div>
            ))}
        </main>
    )
}

export default Intermediary