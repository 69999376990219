import { MainHeader } from '../../shared/frame/MainHeader/MainHeader';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import { useTranslation } from 'react-i18next';
import LiveHelpIcon from '@mui/icons-material/LiveHelp'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import { Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { Typography } from '../../shared/frame/Typography';
import './helpcenter.scss'

export default function HelpCenter() {
    const { t } = useTranslation();
    const guidelist = [
        { id: 'faq', icon: <LiveHelpIcon />, path: '/support/faq' },
        { id: 'portalguide', icon: <SettingsApplicationsIcon />, path: '/support/portalguide' },
        { id: 'feeguide', icon: <RequestQuoteIcon />, path: '/support/feeguide' }
    ] as const;

    return (
        <main>
            <MainHeader title={t('pages.support')} headerHeight={200} titleIcon={<SupportAgentOutlinedIcon />} />
            <section>
                <Typography variant='h4'
                    sx={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: '24px'
                    }}>
                    {t('helpcenter.resource.selfhelp')}
                </Typography>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '20px', marginTop: '20px' }}>
                    {guidelist.map((guide, index) => (
                        <IconButton key={index} aria-label={guide.id} component={Link} to={guide.path}
                            sx={{ border: '2px solid #bcbcbc', color: '#121212', padding: '30px 20px', fontSize: '20px', borderRadius: 2, }}>
                            {guide.icon}
                            <span style={{ marginLeft: 4 }}>{t(`helpcenter.resource.${guide.id}`)}</span>
                        </IconButton>
                    ))}
                </div>
            </section>
        </main>
    );
}