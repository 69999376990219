import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt"
import { Button } from "@quantumsurf/portal-shared-components";
import "./introduceSection.scss"

function IntroduceSection() {

    const { t } = useTranslation()

    /*const contentList = [
        'content.home.introduce.contentItems.one',
        'content.home.introduce.contentItems.two',
        'content.home.introduce.contentItems.three',
        'content.home.introduce.contentItems.four',
        'content.home.introduce.contentItems.five'
    ];*/

    function handleClick() {
        window.location.href = '/support/faq/inquiry/join';
    }
    return (

        <main style={{ backgroundColor: "#f9f9f9" }}>
            <section className="introduce-section">
                <Box className="content" >
                    <Box className="invite">
                        <Typography variant="h3" className="title">{t('content.home.introduce.title')}</Typography>

                        <Typography className="desc" >{t('content.home.introduce.contentTop')}</Typography>
                        <Typography className="desc_short" >{t('content.home.introduce.contentTopShort')}</Typography>
                        {/* <ul style={{ paddingLeft: "20px" }}>
                            {contentList.map((value: string) => <li><Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>{t(value)}</Typography></li>)}
                        </ul>
                        <Typography style={{ fontSize: '20px', fontWeight: 'bold', margin: '30px 0 50px' }}>{t('content.home.introduce.contentBottom')}</Typography> */}

                        <Button className="button" onClick={() => handleClick()}>
                            {t('content.home.introduce.buttonName')}
                            <ArrowRightAltIcon
                                sx={{
                                    color: "#fff",
                                    fontSize: "2rem",
                                    marginLeft: "5px",
                                    marginTop: "0px",
                                }}
                            />
                        </Button>
                    </Box>
                    <Box className="step">
                        <Box className="box">
                            <Typography variant="h3" className="number">{'Step 1'}</Typography>
                            <Typography variant="h3" className="title">{t('content.home.section.step1.title')}</Typography>
                            <Typography className="desc">{t('content.home.section.step1.content')}</Typography>
                        </Box>

                        <Box className="box">
                            <Box sx={{ flex: '1 1 50%' }}>
                                <Typography variant="h3" className="number">{'Step 2'}</Typography>
                                <Typography variant="h3" className="title">{t('content.home.section.step2.title')}</Typography>

                                <Typography className="desc">{t('content.home.section.step2.content')}</Typography>
                            </Box>
                        </Box>

                        <Box className="box">
                            <Box>
                                <Typography variant="h3" className="number">{'Step 3'}</Typography>
                                <Typography variant="h3" className="title">{t('content.home.section.step3.title')}</Typography>

                                <Typography className="desc">{t('content.home.section.step3.content')}</Typography>
                            </Box>
                        </Box>

                    </Box>
                </Box>

            </section>
        </main>
    )
}

export default IntroduceSection;