import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MainHeader } from '../../shared/frame/MainHeader/MainHeader';
import './servicecontent.scss';
import { NoData } from '../NodataPage';
import { useEffect, useRef, useState } from 'react';
import ServiceItemList from './ItemList/serviceitemlist';
import { Typography } from '../../shared/frame/Typography';

interface ServiceItemProps {
    serviceitem: ServiceItem;
}

function Serviceitem({ serviceitem }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate()
    // 언어에 따른 항목 선택
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof ServiceItem['item']] || [];
    // 선택된 항목 찾기
    const selectedItem = selectedLanguageItems.find((item: Item) => item.id === id);

    if (!selectedItem) {
        return <NoData />;
    }

    const link = () => {
        navigate("/support/faq/inquiry/service");
    }

    return (
        <main className='service-item'>
            <MainHeader
                title={selectedItem?.title || ''}
                headerHeight={200}
            >
                <Typography sx={{ color: 'white', marginTop: '20px', marginLeft: '12px' }} variant='body1'>{selectedItem?.description || ''}</Typography>
            </MainHeader>
            <Box>
                <ServiceItemList serviceitem={serviceitem} selectedItemId={selectedItem.id || ''} />
            </Box>
        </main >
    );
};

export default Serviceitem;