import { Typography } from '../../../shared/frame/Typography'
import { Box, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next';
import architecture from '../../../../assets/image/Quantum-X_architecture.png'
import sharingimg from '../../../../assets/image/data_exchange_us_data_sharing.png'
import networkimg from '../../../../assets/image/network.jpg'
import backgroundImage from '../../../../assets/image/background.png'
import React from 'react';
import './../dataspace.scss'

function DsOverview() {
    const { t } = useTranslation('dataspace', { keyPrefix: "content.overview" });
    const X_architecture = architecture;

    return (
        <div className='overview'>
            <div className='dataspace'>
                <div className="dataspace-content">
                    <section>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='h2'>{t('title')}</Typography>
                    </Box>
                    </section>
                </div>
            </div>

            <div className="dataspace-content2">
                    <section>
                <div className='content2-text'>
                    <Typography variant='h4' >{t('point.sovereign.title')}</Typography>
                    <Typography variant='body1' sx={{ margin: '20px 0px' }}>
                        <li>{t('point.sovereign.desc')}</li>
                    </Typography>
                    <Divider sx={{ margin: '15px 0px ', border: 'none' }} />
                    <Typography variant='h4'>{t('point.share.title')}</Typography>
                    <Typography variant='body1' sx={{ margin: '20px 0px' }}>
                        <li>{t('point.share.desc')}</li>
                    </Typography>
                    <Box className='share-content' sx={{ padding: '0px 0px' }}>
                        <Typography variant='h5' sx={{ paddingBottom: '5px' }}>{t('point.share.content.title')}</Typography>
                        <table className='table'>
                            <tbody>
                                <th>{t('point.share.content.exchange')}</th>
                                <td>
                                    <li>{t('point.share.content.exchangedesc1')}</li>
                                    <li>{t('point.share.content.exchangedesc2')}</li>
                                </td>
                                <tr />
                                <th>{t('point.share.content.sharing')}</th>
                                <td>
                                    <li>{t('point.share.content.sharingdesc1')}</li>
                                    <li>{t('point.share.content.sharingdesc2')}</li>
                                </td>
                                <tr />
                            </tbody>
                        </table>
                        <img style={{
                            position: 'relative',
                            display: 'flex',
                            margin: '30px auto 20px auto',
                            width: '100%',
                            borderRadius: 10,
                            objectFit: 'contain'
                        }} src={sharingimg} alt="" />
                    </Box>
                    <Divider sx={{ margin: '15px 0px ', border: 'none' }} />
                </div>
                    </section>
            </div>
            <Box className='company'>
            </Box >
        </div>
    )
}

export default DsOverview