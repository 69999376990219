import { MainHeader } from '../../shared/frame/MainHeader/MainHeader'
import { useTranslation } from 'react-i18next'
import CallOutlinedIcon from '@mui/icons-material/CallOutlined'
import { Box, Tabs, Tab } from "@mui/material";
import { Outlet, useNavigate } from 'react-router-dom';  // 수정된 부분: useNavigate 추가
import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    tabs: {
        "& .MuiTabs-indicator": {
            backgroundColor: "transparent",
        },
        "& .MuiTab-root.Mui-selected": {
            backgroundColor: '#444444',
            color: 'white'
        },
    }
})
export const Inquiry = () => {
    const { t } = useTranslation("", {
        keyPrefix: "content.inquiry",
    });

    const tabsInfo = [
        /*{ label: t("service"), path: "/service" },
        { label: t("solution"), path: "/solution" },
        { label: t("traffic"), path: "/traffic" },
        { label: t("money"), path: "/money" },*/
        { label: t("join"), path: "/join" },
        { label: t("business"), path: "/business" }
    ]

    const [currentTab, setCurrentTab] = React.useState(0);
    const navigate = useNavigate();
    const movelink = '/support/faq/inquiry';
    const classes = useStyles()


    React.useEffect(() => {
        const currentPath = window.location.pathname;
        const currentIndex = tabsInfo.findIndex(tab => currentPath.startsWith(`${movelink}${tab.path}`));
        setCurrentTab(currentIndex !== -1 ? currentIndex : 0);
    }, [movelink, tabsInfo]);

    const handleChange = (_event: any, newValue: any) => {
        setCurrentTab(newValue);
        navigate(`${movelink}${tabsInfo[newValue].path}`);
    }


    return (
        <main className='inquiry'>
            <MainHeader
                headerHeight={100}
                subTitleWidth={1000}
                title={tabsInfo[currentTab].label}
                titleIcon={<CallOutlinedIcon className="header-icon" />}
            />
            {/* {<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <section>
                    <Tabs
                        onChange={handleChange}
                        value={currentTab}
                        variant="fullWidth"
                        centered
                        className={classes.tabs}
                    >
                        {tabsInfo.map((tab, index) => (
                            <Tab key={index} label={tab.label} className={classes.tabs} sx={{ color: '#121212' }} />
                        ))}
                    </Tabs>
                </section>
            </Box>} */}
            <Outlet />
        </main>
    )
}

export default Inquiry;
