import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';
import { Divider } from '@mui/material';

interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function Idprole({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.idp && (
                        <>
                            <div>
                                <Typography variant='h4'>{item.content?.idp?.role?.title}</Typography>
                                <Typography variant='h6'>{item.content?.idp?.role?.subtitle}</Typography>
                                {Object.entries(item.content?.idp?.role?.rolelist || {}).map(([key, value]) => (
                                    <div style={{ marginTop: '10px' }}>
                                        <Typography variant="body1"><li>{value}</li></Typography>
                                    </div>
                                ))}
                                <img className='step-img' src={item.content?.idp?.role?.roleimg} alt={item.content?.idp?.role?.title} />
                                <div className="role">
                                    <div className="technical">
                                        <Typography variant='h6'>{item.content?.idp?.role?.technical?.title}</Typography>
                                        <img className='step-img' src={item.content?.idp?.role?.technical?.img} alt={item.content?.idp?.role?.technical?.title} />
                                    </div>
                                    <Divider sx={{ margin: '10px', border: 'none' }} />
                                    <div className="technical">
                                        <Typography variant='h6'>{item.content?.idp?.role?.registration?.title}</Typography>
                                        <img className='step-img' src={item.content?.idp?.role?.registration?.img} alt={item.content?.idp?.role?.registration?.title} />
                                    </div>
                                    <Divider sx={{ margin: '10px', border: 'none' }} />
                                    <div className="technical">
                                        <Typography variant='h6'>{item.content?.idp?.role?.portal?.title}</Typography>
                                        <img className='step-img' src={item.content?.idp?.role?.portal?.img} alt={item.content?.idp?.role?.portal?.title} />
                                    </div>
                                    <Divider sx={{ margin: '10px', border: 'none' }} />
                                    <div className="wallet">
                                        <Typography variant='h6'>{item.content?.idp?.role?.wallet?.title}</Typography>
                                        <img className='step-img' src={item.content?.idp?.role?.wallet?.img} alt={item.content?.idp?.role?.wallet?.title} />
                                        <Typography variant='body1'>{item.content?.idp?.role?.wallet?.example}</Typography>
                                        {Object.entries(item.content?.idp?.role?.wallet?.list || {}).map(([key, value]) => (
                                            <div>
                                                <Typography variant="body1"><li>{value}</li></Typography>
                                            </div>
                                        ))}
                                    </div>
                                    <Divider sx={{ margin: '10px', border: 'none' }} />
                                    <div className="bpdm">
                                        <Typography variant='h6'>{item.content?.idp?.role?.bpdm?.title}</Typography>
                                        <Typography variant='body1'>{item.content?.idp?.role?.bpdm?.read}</Typography>
                                        <img className='step-img' src={item.content?.idp?.role?.bpdm?.img} alt={item.content?.idp?.role?.bpdm?.title} />
                                        <Typography variant='body1'>{item.content?.idp?.role?.bpdm?.desc}</Typography>
                                    </div>
                                    <Divider sx={{ margin: '10px', border: 'none' }} />
                                    <div className="bpdmgate">
                                        <Typography variant='h6'>{item.content?.idp?.role?.bpdmgate?.title}</Typography>
                                        <img className='step-img' src={item.content?.idp?.role?.bpdmgate?.img} alt={item.content?.idp?.role?.bpdmgate?.title} />
                                    </div>
                                    <Divider sx={{ margin: '10px', border: 'none' }} />
                                    <div className="bpdmshare">
                                        <Typography variant='h6'>{item.content?.idp?.role?.bpdmshare?.title}</Typography>
                                        <Typography variant='body1'>{item.content?.idp?.role?.bpdmshare?.norole}</Typography>
                                        {Object.entries(item.content?.idp?.role?.bpdmshare?.norolelist || {}).map(([key, value]) => (
                                            <div>
                                                <Typography variant="body1"><li>{value}</li></Typography>
                                            </div>
                                        ))}
                                        <Typography variant='body1'>{item.content?.idp?.role?.bpdmshare?.dropdown}</Typography>
                                        <Typography variant='body1'>{item.content?.idp?.role?.bpdmshare?.solution}</Typography>
                                    </div>
                                    <Divider sx={{ margin: '10px', border: 'none' }} />
                                    <div className="segregation">
                                        <Typography variant='h6'>{item.content?.idp?.role?.segregation?.title}</Typography>
                                        <Typography variant='body2'>{item.content?.idp?.role?.segregation?.subtitle}</Typography>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </section>
    )
}

export default Idprole