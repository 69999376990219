import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';

interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function Bpdmbusiness({ serviceitem, selectedItemId }: ServiceItemProps): JSX.Element {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);

    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.bpdm && (
                        <>
                            <Typography variant='h4'>{item.content.bpdm.business?.title}</Typography>
                            <Typography variant='h5'>{item.content.bpdm.business?.subtitle}</Typography>
                            <div className="business">
                                <Typography variant='h6'>{item.content.bpdm.business?.description}</Typography>
                                <img src={item.content.bpdm.business?.img} alt={item.content.bpdm.business?.subtitle} className='step-img' />
                                {['master', 'sme', 'portal', 'value', 'bpdm', 'bpdmapplication', 'curation'].map((category) => {
                                    const dynamicContent = item.content?.bpdm?.business?.[category as keyof typeof item.content.bpdm.business];

                                    if (dynamicContent && typeof dynamicContent === 'object' && 'title' in dynamicContent) {
                                        return (
                                            <div key={category} style={{ margin: '10px 0px' }}>
                                                <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                                                    {dynamicContent.title}
                                                </Typography>
                                                {Object.entries(dynamicContent.list || {}).map(([key, value]) => (
                                                    <Typography key={key} variant="body1">
                                                        <li style={{ margin: '10px 0px' }}>
                                                            {value}
                                                        </li>
                                                    </Typography>
                                                ))}
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                            <div className="technical">
                                <Typography variant='h5'>{item.content.bpdm.technical?.subtitle}</Typography>
                                <img src={item.content.bpdm.technical?.img} alt={item.content.bpdm.technical?.subtitle} className='step-img' />
                                <div style={{ margin: '10px 0px' }}>
                                    {Object.entries(item.content?.bpdm?.technical?.list || {}).map(([key, value]) => (
                                        <>
                                            <Typography variant="body1">
                                                <li style={{ margin: '0px 0px' }}>
                                                    {value}
                                                </li>
                                            </Typography>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </section>
    );
}

export default Bpdmbusiness;
