import axios from "axios";
import { MailData } from "../feature/models/mailData";

enum METHOD {
    GET="get",
    POST="post",
    PUT="put",
    DELETE="delete"
}

//const SERVER_URL = `http://${process.env.REACT_APP_SERVER_HOST}`;
const SERVER_URL = `https://quantum-x.co.kr`;
const URL_ROOT = SERVER_URL+ "/api"


class RequestService {
    sendMail = (mailData:MailData) => {
        return axios({
            method: METHOD.POST,
            url: `${URL_ROOT}/mail/send`,
            data:mailData
        });
    }
}
export default new RequestService();

