import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';

interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function SdfactorySolution({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.sdfactory && (
                        <div>
                            <Typography variant='h4'>{item.content.sdfactory?.solution?.title}</Typography>
                            <Typography variant='h6'>{item.content.sdfactory?.solution?.subtitle}</Typography>
                            <div className="step1">
                                <Typography className='step-title'>{item.content.sdfactory?.solution?.step1?.title}</Typography>
                                <Typography>{item.content.sdfactory?.solution?.step1?.sme}</Typography>
                                <Typography><li>{item.content.sdfactory?.solution?.step1?.legal}</li></Typography>
                                <Typography><li>{item.content.sdfactory?.solution?.step1?.smallcompany}</li></Typography>
                                <Typography><li>{item.content.sdfactory?.solution?.step1?.provider}</li></Typography>
                                <Typography>{item.content.sdfactory?.solution?.step1?.legal}</Typography>
                                <Typography>{item.content.sdfactory?.solution?.step1?.legaldesc}</Typography>
                                <img className='step-img' src={item.content.sdfactory?.solution?.step1?.legalimg} alt="" />
                            </div>
                            <div className="step2">
                                <Typography className='step-title'>{item.content.sdfactory?.solution?.step2?.title}</Typography>
                                <Typography>{item.content.sdfactory?.solution?.step2?.physical}</Typography>
                                <Typography>{item.content.sdfactory?.solution?.step2?.physcicalresource}</Typography>
                                <Typography>{item.content.sdfactory?.solution?.step2?.imgdesc}</Typography>
                                <img className='step-img' src={item.content.sdfactory?.solution?.step2?.img} alt="" />
                            </div>
                            <div className="step3">
                                <Typography className='step-title'>{item.content.sdfactory?.solution?.step3?.title}</Typography>
                                <Typography>{item.content.sdfactory?.solution?.step3?.assumption}</Typography>
                                <Typography><li>{item.content.sdfactory?.solution?.step3?.sd_docs1}</li></Typography>
                                <Typography><li>{item.content.sdfactory?.solution?.step3?.sd_docs2}</li></Typography>
                                <Typography>{item.content.sdfactory?.solution?.step3?.resource}</Typography>
                                <Typography>{item.content.sdfactory?.solution?.step3?.offering}</Typography>
                                <Typography>{item.content.sdfactory?.solution?.step3?.wallet}</Typography>
                                <img className='step-img' src={item.content.sdfactory?.solution?.step3?.img} alt="" />
                            </div>

                        </div>
                    )}
                </div>
            ))}
        </section>
    )
}

export default SdfactorySolution