import React from 'react';
import ReactDOM from 'react-dom/client';
import I18nService from './services/I18nService';
import AuthorizingRouter from './components/AuthorizingRouter';
import { SharedCssBaseline, SharedThemeProvider } from '@quantumsurf/portal-shared-components'
import { StyledEngineProvider } from '@mui/material';

I18nService.init()


const root = ReactDOM.createRoot(
  document.getElementById('app') as HTMLElement
);
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <SharedCssBaseline />
      <SharedThemeProvider>
        <AuthorizingRouter />
      </SharedThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
);

