import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import '../inquiry.scss'
import { useTranslation } from "react-i18next";
import { Typography } from "../../../shared/frame/Typography";
import { useState } from "react";
import { Dropzone } from "../../../shared/basic/DropArea";
import { DropArea } from "../../../shared/basic/Dropzone/components/DropArea";
import { Dialog } from "../../../shared/basic/Dialog";
import { DialogContent } from "../../../shared/basic/Dialog/DialogContent";
import { DialogActions } from "../../../shared/basic/Dialog/DialogActions";
import { DialogHeader } from "../../../shared/basic/Dialog/DialogHeader";
import Inquiry from "..";


// type InquiryDataType = {
//     type: string
//     title: string
//     description: string
//     email: string
//     phone: string
//     privacycheckbox: string
//     uploadfile: File | null
// }
export default function ServiceInquriy() {
    const { t } = useTranslation("", {
        keyPrefix: "content.inquiry",
    })
    const margin = { mt: 2, mb: 2, mr: -2, ml: -2 }
    const selectList = ["apple", "banana", "grape", "orange"];
    const numberList = ["+82", "+81", "+49"];

    const [hasError, setHasError] = useState<boolean>(false);
    const [selected, setSelected] = useState<string>("");
    const [phoneselected,] = useState<string>("")
    const [, setUploadedFile] = useState(null);

    const [openModal, setOpenModal] = useState<string | null>(null);

    const handleSelect = (e: any) => {
        setSelected(e.target.value as string);
        setHasError(false);
    };

    const handleModalOpen = (modalType: string) => {
        setOpenModal(modalType);
    };

    const handleModalClose = () => {
        setOpenModal(null);
    };

    const handleDropzoneChange = (files: any) => {
        setUploadedFile(files.length > 0 ? files[0] : null);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!selected) {
            setHasError(true);
        }
    };

    return (
        <main className="inquiry">
            <Inquiry />
            <Box className='inquirybox'>
                <form onSubmit={handleSubmit}>
                    <InputLabel>
                        <Typography className="inquirytitle">{t('inquiry.type')}
                            <span style={{ color: 'red' }}> *</span>
                        </Typography>
                    </InputLabel>
                    <FormControl error={hasError} fullWidth>
                        <Select
                            onChange={handleSelect} value={selected}
                            sx={{ width: '100%', Height: '30px' }}
                            displayEmpty
                            renderValue={(v) => (v?.length ? v : t('inquiry.typeplaceholder'))}
                        >

                            {selectList.map((item) => (
                                <MenuItem value={item} key={item} >
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                        {hasError && <FormHelperText>{t('inquiry.typeerror')}</FormHelperText>}
                    </FormControl>

                    <Divider sx={{ ...margin }} />

                    <InputLabel>
                        <Typography className="inquirytitle">{t('inquiry.title')}
                            <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <FormControl error={hasError} fullWidth>
                            <TextField fullWidth placeholder={t('inquiry.titleplaceholder')}></TextField>
                            {hasError && <FormHelperText>{t('inquiry.titleerror')}</FormHelperText>}
                        </FormControl>
                    </InputLabel>

                    <Divider sx={{ ...margin }} />

                    <InputLabel>
                        <Typography className="inquirytitle">{t('inquiry.content')}
                            <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <FormControl error={hasError} fullWidth>
                            <TextField multiline fullWidth rows={10} maxRows={20} placeholder={t('inquiry.contentplaceholder')}></TextField>
                            {hasError && <FormHelperText>{t('inquiry.contenterror')}!</FormHelperText>}
                        </FormControl>
                    </InputLabel>

                    <Divider sx={{ ...margin }} />

                    <InputLabel>
                        <Typography className="inquirytitle">{t('inquiry.address')}
                            <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <FormControl error={hasError} fullWidth>
                            <TextField fullWidth placeholder={t('inquiry.addressplaceholder')}></TextField>
                            {hasError && <FormHelperText>{t('inquiry.addresserror')}</FormHelperText>}
                        </FormControl>
                    </InputLabel>

                    <Divider sx={{ ...margin }} />

                    <InputLabel>
                        <Typography className="inquirytitle">{t('inquiry.phone')}
                            <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <FormControl error={hasError} fullWidth>
                            <div className="phone">
                                <Select
                                    displayEmpty
                                    renderValue={(v) => (v?.length ? v : t('inquiry.phoneselectplaceholder'))}
                                    onChange={handleSelect} value={phoneselected} sx={{ Height: '30px' }} aria-selected>
                                    {numberList.map((item) => (
                                        <MenuItem value={item} key={item} style={{ padding: '10px 15px' }}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <TextField fullWidth placeholder={t('inquiry.phoneplaceholder')}></TextField>
                                {hasError && <FormHelperText sx={{ width: '300px' }}>{t('inquiry.phoneerror')}</FormHelperText>}
                            </div>
                        </FormControl>
                    </InputLabel>

                    <Divider sx={{ ...margin }} />

                    <InputLabel>
                        <Typography className="inquirytitle">
                            {t('inquiry.file')}
                        </Typography>

                        <Dropzone
                            maxFilesToUpload={1}
                            onChange={handleDropzoneChange}
                            DropStatusHeader={false}
                            DropArea={(props) => <DropArea {...props} size="normal" />}
                            errorText={t('shared.dropzone.errorTitle')}
                        />
                        <FormHelperText sx={{ fontSize: '14px' }}>{t('inquiry.fileplaceholder')}</FormHelperText>
                    </InputLabel>

                    <Divider sx={{ ...margin }} />

                    <InputLabel>
                        <Typography className="inquirytitle">
                            {t('inquiry.term')}
                            <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <FormControl error={hasError} fullWidth>
                            <FormGroup>
                                {['privacy', 'sns'].map((modalType) => (
                                    <div className="termbox" key={modalType}>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={t(`inquiry.termcheckbox.${modalType}.title`)}
                                            sx={{ color: 'black' }}
                                        />
                                        <Button variant="outlined" onClick={() => handleModalOpen(modalType)}>
                                            {t('inquiry.termcontent')}
                                        </Button>
                                    </div>
                                ))}
                            </FormGroup>
                            {hasError && <FormHelperText sx={{ width: '300px' }}>{t('inquiry.phoneerror')}</FormHelperText>}
                        </FormControl>
                    </InputLabel>

                    <Divider sx={{ ...margin }} />

                    <Button type="submit" variant="outlined"
                        sx={{
                            display: 'flex',
                            padding: '20px 0px ',
                            margin: '0px auto',
                            maxWidth: '300px',
                            width: '100%',
                            fontSize: '18px',
                            minWidth: '200px'
                        }}>등록하기</Button>
                    <Dialog open={!!openModal}>
                        {openModal && (
                            <>
                                <DialogHeader>
                                    <Typography variant="h5" sx={{ color: 'white' }}>{t(`inquiry.termcheckbox.${openModal}.title`)}</Typography>
                                </DialogHeader>
                                <DialogContent>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{t(`inquiry.termcheckbox.${openModal}.subtitle`)}</Typography>
                                    <Box sx={{ margin: '20px' }}>
                                        {[1, 2, 3].map((section) => {
                                            const titleKey = `inquiry.termcheckbox.${openModal}.content${section}.title`;
                                            const contentItems = [1, 2].map((item) => t(`inquiry.termcheckbox.${openModal}.content${section}.item${item}`));

                                            if (!t(`inquiry.termcheckbox.${openModal}.content${section}.title`)) {
                                                return null;
                                            }

                                            return (
                                                <div key={section}>
                                                    <Typography variant="h6" sx={{ fontWeight: 'bolder', fontSize: '15px' }}>{t(titleKey)}</Typography>
                                                    {(contentItems.some(item => item.trim() !== '')) && (
                                                        <ul className="termlist">
                                                            {contentItems.map((item, index) => (
                                                                item.trim() !== '' && <li key={index}>{item}</li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </Box>
                                    <Typography variant="h6" sx={{ fontWeight: 'bolder', fontSize: '15px', margin: '20px' }}>
                                        {t(`inquiry.termcheckbox.${openModal}.etc`)}
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleModalClose} color="primary">
                                        {t('inquiry.close')}
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                    </Dialog>


                </form>
            </Box>

        </main >
    )
}
