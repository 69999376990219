import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';
import { Divider } from '@mui/material';

interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function Idpoverview({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.idp && (
                        <>
                            <Typography variant='h4'>{item.content.idp.intro?.title}</Typography>
                            <div className="broker">
                                <Typography variant='body1'>{item.content.idp.intro?.borkerdesc1}</Typography><br />
                                <Typography variant='body1'>{item.content.idp.intro?.borkerdesc2}</Typography><br />
                                <Typography variant='body1'>{item.content.idp.intro?.borkerdesc3}</Typography>
                                {Object.entries(item.content?.idp?.intro?.brokerlist || {}).map(([key, value]) => (
                                    <>
                                        <Typography variant="body1"><li>{value}</li></Typography>
                                    </>
                                ))}
                            </div>
                            <br />
                            <div className="provider">
                                <Typography variant='body1'>{item.content.idp.intro?.idpprovider}</Typography>
                                {Object.entries(item.content?.idp?.intro?.providerlist || {}).map(([key, value]) => (
                                    <>
                                        <Typography variant="body1"><li>{value}</li></Typography>
                                    </>
                                ))}
                            </div>
                            <Divider sx={{ margin: '10px', border: 'none' }} />
                            <div className="oidc">
                                <Typography variant='h5'>{item.content.idp.intro?.oidc.oidctitle}</Typography>
                                <img className='step-img' src={item.content.idp.intro?.oidc.oidcimg} alt={item.content.idp.intro?.oidc.oidctitle} />
                            </div>
                        </>
                    )}
                </div>
            ))}
        </section>
    )
}

export default Idpoverview