import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';

interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function DigitalTwinfeature({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.digitaltwin && (
                        <>
                            <div className="digitaltwin">
                                <Typography variant='h4'>{item.content?.digitaltwin?.step1?.title}</Typography>
                                <Typography variant='body1'>{item.content?.digitaltwin?.description}</Typography>
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Typography variant='h6'>{item.content?.digitaltwin?.step1?.requesttitle}</Typography>
                                <Typography variant='h6'>{item.content?.digitaltwin?.step1?.request}</Typography>
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Typography variant='h6'>{item.content?.digitaltwin?.step1?.goaltitle}</Typography>
                                {Object.entries(item.content?.digitaltwin?.step1?.goal || {}).map(([key, value]) => (
                                    <div>
                                        <Typography variant="body1"><li>{value}</li></Typography>
                                    </div>
                                ))}
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '20%', minWidth: '100px' }}>{item.content?.digitaltwin?.step1?.table?.role}</th>
                                            <th style={{ width: '50%', minWidth: '250px' }}>{item.content?.digitaltwin?.step1?.table?.desc}</th>
                                            <th style={{ width: '30%', minWidth: '100px' }}>{item.content?.digitaltwin?.step1?.table?.goal}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{item.content?.digitaltwin?.step1?.table?.provider?.role}</td>
                                            <td>{item.content?.digitaltwin?.step1?.table?.provider?.desc}</td>
                                            <td>{item.content?.digitaltwin?.step1?.table?.provider?.goal}</td>
                                        </tr>
                                        <tr>
                                            <td>{item.content?.digitaltwin?.step1?.table?.consumer?.role}</td>
                                            <td>{item.content?.digitaltwin?.step1?.table?.consumer?.desc}</td>
                                            <td>{item.content?.digitaltwin?.step1?.table?.consumer?.goal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </section>
    )
}

export default DigitalTwinfeature