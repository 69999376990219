import { useTranslation } from "react-i18next"
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt"
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark"
import { MainHeader } from "../../shared/frame/MainHeader/MainHeader"
import { Button, Typography } from "@quantumsurf/portal-shared-components"
import onboarding_ko from '../../../assets/image/chart_onboarding_ko.png'
import onboarding_en from '../../../assets/image/chart_onboarding_en.png'
import onboarding_mobile_ko_1 from '../../../assets/image/chart_onboarding_ko_1.png'
import onboarding_mobile_ko_2 from '../../../assets/image/chart_onboarding_ko_2.png'
import onboarding_mobile_ko_3 from '../../../assets/image/chart_onboarding_ko_3.png'
import onboarding_mobile_en_1 from '../../../assets/image/chart_onboarding_en_1.png'
import onboarding_mobile_en_2 from '../../../assets/image/chart_onboarding_en_2.png'
import onboarding_mobile_en_3 from '../../../assets/image/chart_onboarding_en_3.png'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore from "swiper";
import { useEffect, useState } from "react"
import { Box, debounce } from "@mui/material"

export default function Onboarding() {
  const { t, i18n } = useTranslation()
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [isArrow, setIsArrow] = useState({
    left:true,
    right:false
  });

  function handleClick() {
    window.location.href = '/support/faq/inquiry/join';
  }

  const useItem: any = [
  ]
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const handleResize = debounce(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }, 10);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => { // cleanup 
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  return (
    <main>
      {windowSize.width > 800 && <section className="main-section">
        <img src={i18n.language === 'ko' ?  onboarding_ko : onboarding_en} style={{ position: 'relative', width: '100%'}} />

        <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
          <div style={{flex:'1 1 100%', textAlign:'center', marginTop:20}}>
            <Typography variant="h2" style={{fontWeight:'bold'}}>
              {t('content.onboarding.content')}
            </Typography>
          </div>
          <div style={{marginTop:50}}>
            <Button className="button" onClick={() => handleClick()} size="large">
              {t('content.onboarding.buttonName')}
              <ArrowRightAltIcon
                sx={{
                  color: "#fff",
                  fontSize: "2rem",
                  marginLeft: "5px",
                  marginTop: "0px",
                }}
              />
            </Button>
          </div>
        </div>
      </section>}
      {windowSize.width <= 800 && <Box>
        <div className="wrapper">
          <div className="button-wrapper">
            <div style={{zIndex:400}}>
              {isArrow.left == false && <KeyboardArrowLeftIcon sx={{ color: "#333", width: "20px", fontSize: "20px" }} onClick={() => {
                if (swiper) {
                  swiper.slidePrev();
                }
              }} />}
            </div>
            <div style={{zIndex:400}}>
              {isArrow.right == false && <KeyboardArrowRightIcon sx={{ color: "#333", width: "20px", fontSize: "20px" }}
                onClick={() => {
                  if (swiper) {
                    swiper.slideNext();
                  }
                }} />}
            </div>
          </div>
          <Swiper
            pagination={true}
            spaceBetween={20}
            slidesPerView={windowSize.width > 800 ? Math.floor(windowSize.width / 280) : 1}
            setWrapperSize={true}
            onSwiper={(swiper) => {
              setSwiper(swiper);
            }}
            onSlideChange={(swiper) => {
              setIsArrow({left:swiper?.isBeginning, right:swiper?.isEnd})
            }}
          >
            <SwiperSlide key={1}>
              <Box sx={{ position: 'relative' }}>
                <img src={i18n.language === 'ko' ? onboarding_mobile_ko_1 : onboarding_mobile_en_1} style={{ position: 'absolute', width: '95%' }} />
              </Box>
            </SwiperSlide>
            <SwiperSlide key={2}>
              <img src={i18n.language === 'ko' ? onboarding_mobile_ko_2 : onboarding_mobile_en_2} style={{ position: 'relative', width: '95%' }} />
            </SwiperSlide>
            <SwiperSlide key={3}>
              <img src={i18n.language === 'ko' ? onboarding_mobile_ko_3 : onboarding_mobile_en_3} style={{ position: 'relative', width: '95%' }} />
            </SwiperSlide>
            <SwiperSlide key={4}>
              <Box>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div style={{ flex: '1 1 100%', textAlign: 'center', marginTop: 20 }}>
                    <Typography variant="h2" style={{ fontWeight: 'bold' }}>
                      {t('content.onboarding.content')}
                    </Typography>
                  </div>
                  <div style={{ marginTop: 50 }}>
                    <Button className="button" onClick={() => handleClick()} size="large">
                      {t('content.onboarding.buttonName')}
                      <ArrowRightAltIcon
                        sx={{
                          color: "#fff",
                          fontSize: "2rem",
                          marginLeft: "5px",
                          marginTop: "0px",
                        }}
                      />
                    </Button>
                  </div>
                </div>
              </Box>
            </SwiperSlide>
          </Swiper>
        </div>
      </Box>}
    </main>
  )
}
