import React from "react";
import { useTranslation } from "react-i18next";
import { SwiperHeaderTitle } from "../../../../shared/basic/SwiperHeader/Component/SwiperHeadertitle";
import serviceitem from "../../../../../item/serviceitem.json";
import { Box, Tabs, Tab, Avatar } from "@mui/material";
import { Typography } from "../../../../shared/frame/Typography";
import "./servicesection.scss"
import { Button } from "../../../../shared/frame/Button";
import { Link } from "react-router-dom";



function TabPanel({ children, value, index, ...other }: any) {
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box p={1.1}>
          <Typography>{children}</Typography>
        </Box>
      )
      }
    </div >
  );
}

function ServiceSection() {
  const { t, i18n } = useTranslation();
  const [currentTab, setCurrentTab] = React.useState(0);
  const serviceitems: Item[] | undefined = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item];

  const handleChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setCurrentTab(newTab);
  };

  return (
    <main style={{ backgroundColor: "#f9f9f9" }}>
      <section className="service-section" style={{width:'100%'}}>
        <div className="swiper-option">
          <SwiperHeaderTitle title={t("content.home.service.title")} titleTextVariant="h4" titleStyle={{fontWeight: 'bold', fontSize: '40px;'}} />
        </div>
        <div className="wrapper">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              onChange={handleChange}
              value={currentTab}
            >
              {/* {serviceitems?.map((item, index) => (
                <Tab key={index} label={item.category} className="tab" icon={<Avatar alt={item.id} src={item.category} className="tab-image" />} />
              ))} */}
              {serviceitems?.map((item, index) => (
                <Tab key={index} label={item.category} className="tab" />
              ))}
            </Tabs>
          </Box>
          {serviceitems?.map((item, index) => (
            <TabPanel key={index} value={currentTab} index={index} >
              {/* Content for each service item */}
              <div className="service">
                <Box className="servicebox1">
                  <div className="servicetitle">
                    <Typography variant="h4">{item.title}</Typography>
                  </div>
                </Box>
                <Box className="servicebox2">
                  <div className="servicedetail">
                    <div className="header">
                      <Typography variant="h5"><span  style={{textAlign:'center'}}>{item.content?.title}</span></Typography>
                      {/* <Button variant="outlined">
                        <Link to={`/service/${item.id}?category=${item.category}`}>
                          {t("global.detail")}
                        </Link>
                      </Button> */}
                    </div>
                    <Box className="content-box">
                      <div style={{ padding: "10px 20px" }}>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>{item.description}</Typography>
                      </div>
                    </Box>
                  </div>
                </Box>
              </div>
            </TabPanel>
          ))}
        </div>
      </section>
    </main>
  );
};

export default ServiceSection;