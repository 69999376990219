import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';

interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function DigitalTwinbusiness({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.digitaltwin && (
                        <>
                            <Typography variant='h4'>{item.content?.digitaltwin?.step2?.title}</Typography>
                            <Typography variant='body1'>{item.content?.digitaltwin?.step2?.desc}</Typography>
                            <div style={{ margin: '10px 0px' }} />
                            <div className="environment">
                                <Typography variant='h6'>{item.content?.digitaltwin?.step2?.environment}</Typography>
                                <img className='step-img' src={item.content?.digitaltwin?.step2?.environmentimg} alt="" />
                            </div>
                            <div style={{ margin: '10px 0px' }} />
                            <div className="achitecture">
                                <Typography variant='h6'>{item.content?.digitaltwin?.step2?.achitecture}</Typography>
                                <img className='step-img' src={item.content?.digitaltwin?.step2?.achitectureimg} alt="" />
                            </div>
                            <div style={{ margin: '10px 0px' }} />
                            <div className="shell">
                                <Typography variant='h6'>{item.content?.digitaltwin?.step2?.shellmodel}</Typography>
                                <Typography variant='body1'>{item.content?.digitaltwin?.step2?.shellmodeldesc}</Typography>
                                <img className='step-img' src={item.content?.digitaltwin?.step2?.shellmodelimg} alt="" />
                            </div>
                            <div style={{ margin: '10px 0px' }} />
                            <div className="constraint">
                                <Typography variant='h6'>{item.content?.digitaltwin?.step2?.constraint.title}</Typography>
                                <Typography variant='body1'>{item.content?.digitaltwin?.step2?.constraint.frist}</Typography>
                                <Typography variant='body1'>{item.content?.digitaltwin?.step2?.constraint.second}</Typography>
                                <Typography variant='body1'>{item.content?.digitaltwin?.step2?.constraint.third}</Typography>
                            </div>
                            <div style={{ margin: '10px 0px' }} />
                            <div className="architecture">
                                <Typography variant='h6'>{item.content?.digitaltwin?.step2?.architecture.title}</Typography>
                                <Typography variant='body1'>{item.content?.digitaltwin?.step2?.architecture.frist}</Typography>
                                <Typography variant='body1'>{item.content?.digitaltwin?.step2?.architecture.second}</Typography>
                                <Typography variant='body1'>{item.content?.digitaltwin?.step2?.architecture.third}</Typography>
                            </div>
                        </>
                    )}
                </div>
            ))
            }
        </section >
    )
}

export default DigitalTwinbusiness