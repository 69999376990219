import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../shared/frame/Typography';
import { Divider } from '@mui/material';

interface ServiceItemProps {
    serviceitem: ServiceItem;
    selectedItemId: string;
}

function Idpclient({ serviceitem, selectedItemId }: ServiceItemProps) {
    const { t, i18n } = useTranslation();
    const selectedLanguageItems = serviceitem.item?.[i18n.language as keyof typeof serviceitem.item] || [];
    const contentItems = selectedLanguageItems.filter((item) => item.id === selectedItemId && item.content && item.content.title);
    return (
        <section className='service-feature'>
            {contentItems.map((item) => (
                <div key={item.id}>
                    {item.content?.idp && (
                        <>
                            <div className="client">
                                <Typography variant='h4'>{item.content?.idp.client.title}</Typography>
                                <Typography variant='h6'>{item.content?.idp.client.subtitle}</Typography>
                                <Typography variant='body1'>{item.content?.idp.client.dft}</Typography>
                                <Typography variant='body1'>{item.content?.idp.client.flow}</Typography>
                                {Object.entries(item.content?.idp?.client?.scenario || {}).map(([key, value]) => (
                                    <>
                                        <Typography variant="body1"><li>{value}</li></Typography>
                                    </>
                                ))}
                                <img src={item.content?.idp?.client?.scenarioimg} alt="" className='step-img' />
                                <Typography variant='body1'>{item.content?.idp.client.scenariodesc}</Typography>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </section>
    )
}

export default Idpclient